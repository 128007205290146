import { FiMail, FiLock, FiUser, FiPhone } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import _ from 'lodash';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card } from '../../components/Card';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { schemaSignUp } from './schemaValidation';

import * as S from './styles';
import { useAuth } from '../../hooks/auth';

interface SignUpForm {
  username: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
}

export function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { signUp, user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForm>({
    resolver: yupResolver(schemaSignUp),
  });

  const onSubmitSignUp = handleSubmit(
    async (newUserCredentials: SignUpForm) => {
      setIsLoading(true);
      try {
        const credentialsSignUp = _.omit(
          newUserCredentials,
          'passwordConfirmation',
        );
        await signUp(credentialsSignUp);
      } catch (error) {
        switch (error.response.data.message) {
          case 'user.username_already_exists':
            toast.error('O nome de usuário já existe.');
            break;
          case 'user.email_already_exists':
            toast.error('O e-mail informado já está cadastrado.');
            break;
          default:
            toast.error('Não foi possível criar o usuário');
            break;
        }
      }
      toast.info('Por favor verifique seu e-mail para confirmar sua conta');
      setIsLoading(false);
      history.push('/');
    },
  );

  return (
    <S.Container>
      <Card>
        <S.Content>
          <S.Title>Bem-vindo ao Noubee</S.Title>
          <form onSubmit={onSubmitSignUp}>
            <div className="input-container">
              <Input
                icon={FiUser}
                error={errors.username?.message ?? null}
                placeholder="nome de usuário"
                {...register('username')}
              />
              <span>{errors.username?.message}</span>
            </div>

            <div className="input-container">
              <Input
                icon={FiMail}
                placeholder="email"
                type="email"
                {...register('email')}
                error={errors.email?.message ?? null}
              />
              <span>{errors.email?.message}</span>
            </div>

            <div className="input-container">
              <Input
                icon={FiPhone}
                placeholder="número de telefone"
                type="text"
                {...register('phone')}
                error={errors.phone?.message ?? null}
              />
              <span>{errors.phone?.message}</span>
            </div>

            <div className="input-container">
              <Input
                icon={FiLock}
                placeholder="senha"
                type="password"
                {...register('password')}
                error={errors.password?.message ?? null}
              />
              <span>{errors.password?.message}</span>
            </div>

            <div className="input-container">
              <Input
                icon={FiLock}
                placeholder="confirmação de senha"
                type="password"
                {...register('passwordConfirmation')}
                error={errors.passwordConfirmation?.message ?? null}
              />
              <span>{errors.passwordConfirmation?.message}</span>
            </div>

            <Button
              size="fullWidth"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Cadastrar
            </Button>
          </form>
        </S.Content>
      </Card>
    </S.Container>
  );
}
