import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { Header } from './components/Header';
import AppProvider from './hooks';
import { Routes } from './routes';
import GlobalStyles from './styles/global';
import theme from './styles/theme';
import { Footer } from './components/Footer';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <BrowserRouter>
          <GlobalStyles />
          <Header />
          <Routes />
          <Footer />
          <ToastContainer autoClose={5000} />
        </BrowserRouter>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
