import { ButtonHTMLAttributes } from 'react';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';

interface ButtonPropsStyles extends ButtonHTMLAttributes<HTMLButtonElement> {
  secondary: boolean;
  size: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'fullWidth';
  header: boolean;
  isLoading: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg)
  }
`;

const wrapperModifiers = {
  xxsmall: (theme: DefaultTheme) => css`
    height: 2.8rem;
    padding: 0 ${theme.spacings.xsmall};
  `,
  xsmall: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.xsmall};
  `,
  small: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.small};
  `,
  medium: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.medium};
  `,
  large: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.large};
  `,
  fullWidth: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.xxlarge};
    width: 100%;
  `,
  disabled: () => css`
    &:disabled {
      cursor: not-allowed;
      filter: saturate(30%);
    }
  `,
  isLoading: () => css`
    > svg {
      animation: ${rotate} 2s linear infinite;
    }

    &:disabled {
      cursor: not-allowed;
      filter: saturate(30%);
    }
  `,
  secondary: (header: boolean, theme: DefaultTheme) => css`
    background: ${theme.colors.primary};
    font-weight: ${header ? theme.font.bolder : theme.font.normal};
    text-transform: ${header ? 'uppercase' : 'default'};
    font-family: ${header ? "'Bebas Neue', cursive" : "'Roboto', sans-serif"};
    font-size: ${header
      ? `calc(2 * ${theme.font.sizes.xsmall})`
      : theme.font.sizes.medium};
  `,
};

export const ButtonWrapper = styled.button<ButtonPropsStyles>`
  ${({ theme }) => css`
    background: linear-gradient(
      to top,
      ${theme.colors.secondary},
      ${theme.colors.primary}
    );
    color: ${theme.colors.text};
    border: none;
    border-radius: ${theme.border.radius};
    font-size: ${theme.font.sizes.medium};
    height: 5rem;
    transition: filter 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    font-family: 'Roboto', sans-serif;

    svg {
      margin-right: ${theme.spacings.xxsmall};
      font-size: ${theme.font.sizes.xxlarge};
    }

    &:hover {
      filter: brightness(90%);
    }
  `}

  ${({ size, theme }) => !!size && wrapperModifiers[size](theme)};
  ${({ disabled }) => disabled && wrapperModifiers.disabled()};
  ${({ isLoading }) => isLoading && wrapperModifiers.isLoading()};
  ${({ secondary, theme, header }) =>
    !!secondary && wrapperModifiers.secondary(header, theme)}
`;
