import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} ${theme.spacings.xsmall};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: calc(2 * ${theme.font.sizes.xlarge});
    margin-bottom: ${theme.spacings.medium};
  `}
`;

export const Table = styled.table`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  border-collapse: collapse;

  thead {
    font-weight: 700;
    font-size: 20px;

    th {
      text-align: left;
    }
  }

  .right-align {
    text-align: right;
  }

  .winner {
    color: ${({ theme }) => theme.colors.secondary};
  }

  tbody {
    font-weight: 400;
    font-size: 16px;

    tr {
      border-bottom: 1px solid #fff;

      td {
        padding: 8px 0;

        a {
          text-decoration: none;
          color: #fff;
          transition: color 0.3s;

          &:hover {
            color: ${({ theme }) => theme.colors.secondary};
          }
        }
      }
    }
  }
`;
