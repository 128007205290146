import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from 'react';

import * as S from './styles';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const DatePickerBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { id, ...rest },
  ref,
) => {
  return <S.Container {...rest} ref={ref} id={id} type="date" />;
};

export const DatePicker = forwardRef(DatePickerBase);
