import { AxiosPromise } from 'axios';
import api from '../config/api';

type DataPaymentProps = {
  cur: string;
  amount: number;
  bid_id: number;
};

export const createPaymentIntent = (
  dataPayment: DataPaymentProps,
  token: string,
): AxiosPromise =>
  api.post('/payment', dataPayment, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

export const getStripeKey = (token: string): AxiosPromise =>
  api.get('/payment/stripe_key', {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
