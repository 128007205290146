import { FiLoader } from 'react-icons/fi';

import * as S from './styles';

export function Loader() {
  return (
    <S.Container>
      <FiLoader />
    </S.Container>
  );
}
