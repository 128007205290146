import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from 'react';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const RadioBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { id, ...rest },
  ref,
) => {
  return <input type="radio" {...rest} id={id} ref={ref} />;
};

export const Radio = forwardRef(RadioBase);
