import { AxiosPromise } from 'axios';
import api from '../config/api';
import api2 from '../config/api2';

type SignInCredentials = {
  email: string;
  password: string;
};

type SignUpData = {
  username: string;
  password: string;
  email: string;
  phone: string;
};

type ForgotPasswordData = {
  email: string;
};

type ResetPasswordData = {
  password: string;
  password_confirmation: string;
};

export const signInRequest = ({
  email,
  password,
}: SignInCredentials): AxiosPromise =>
  api.post('/auth/sign_in', { email, password });

export const signUpRequest = ({
  username,
  password,
  email,
  phone,
}: SignUpData): AxiosPromise =>
  api.post('/auth', { username, password, email, phone });

// ask for change of password
export const lostPasswordRequest = ({
  email,
}: ForgotPasswordData): AxiosPromise => api.post('/auth/password', { email });

// changes password
export const resetPasswordRequest = ({
  password,
  password_confirmation,
}: ResetPasswordData): AxiosPromise =>
  api2.put('/auth/password', {
    password,
    password_confirmation,
  });
