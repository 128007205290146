import { FiClock } from 'react-icons/fi';
import { intervalToDuration } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../Button';
import * as S from './styles';
import { formatMonetaryValue } from '../../utils/formatMonetaryValue';

interface ProductCardProps {
  photoURL: string;
  price: number;
  title: string;
  photoOrganizerURL: string;
  dateEnd: Date;
  charity: boolean;
  id: number;
}

export function ProductCard({
  photoURL,
  price,
  title,
  photoOrganizerURL,
  dateEnd,
  id,
  charity = false,
}: ProductCardProps) {
  const history = useHistory();
  const timeToShowEndingSoon = 5; // in hours
  const [onHoverEndingSoon, setOnHoverEndingSoon] = useState(false);
  const [timeToEnding, setTimeToEnding] = useState(
    intervalToDuration({
      start: new Date(),
      end: dateEnd,
    }),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeToEnding(
        intervalToDuration({
          start: new Date(),
          end: dateEnd,
        }),
      );
    }, 1000 * 60 * 1); // 1 minuto para atualizar o relógio no card

    return () => clearInterval(intervalId);
  }, [timeToEnding, dateEnd]);

  const daysTotal =
    (timeToEnding?.months ?? 0) * 30 + (timeToEnding?.days ?? 0);
  const hoursTotal = daysTotal * 24 + (timeToEnding?.hours ?? 0);

  function navigateToInfoPage() {
    history.push(`/leilao/${id}`);
  }

  const formatFeeValue = useMemo(() => {
    formatMonetaryValue(price);
  }, [price]);

  return (
    <S.Container>
      <S.Photo photoURL={photoURL} />
      <S.TimeToEnding
        onMouseEnter={() => setOnHoverEndingSoon(true)}
        onMouseLeave={() => setOnHoverEndingSoon(false)}
        endingSoonIsVisible={onHoverEndingSoon}
      >
        {hoursTotal <= timeToShowEndingSoon && (
          <div className="ending-soon">ÚLTIMAS HORAS!!</div>
        )}
        <FiClock />
        {`${daysTotal} dia(s) : ${timeToEnding.hours} hora(s) : ${timeToEnding.minutes} min(s)`}
      </S.TimeToEnding>
      <S.InfoProducts>
        <span>{title}</span>
        <Button size="xxsmall" onClick={navigateToInfoPage}>
          R$ {formatFeeValue}
        </Button>
      </S.InfoProducts>

      {/* <S.PhotoOrganizer photoOrganizerURL={photoOrganizerURL} /> */}
      {charity && <S.CharityChip>Caridade!</S.CharityChip>}
    </S.Container>
  );
}
