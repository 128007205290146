import { Link } from 'react-router-dom';
import { Card } from '../../components/Card';

import * as S from './styles';

export function Faq() {
  return (
    <S.Container>
      <Card>
        <h1>FAQ</h1>

        <S.Questions>
          <h2>Qual é a diferença do Noubee para um leilão normal?</h2>
          <p>
            O leilão tradicional recompensa sempre o maior lance, na Noubee
            damos a oportunidade de ganhar recompensas incríveis por qualquer
            preço!
          </p>
          <h2>Por que usar a Noubee?</h2>
          <p>
            Não cobramos taxas abusivas ou mecanismos de sorte ou apostas. A
            Noubee é uma plataforma justa para todos os usuários.
          </p>
          <h2>Como participar de um leilão?</h2>
          <p>Crie uma conta e de um lance, simples assim!</p>
          <h2>Quais são as regras?</h2>
          <p>
            Temos tudo explicado <Link to="/como-funciona">aqui!</Link>
          </p>
          <h2>
            Posso criar meu próprio leilão para oferecer meus produtos ou
            experiências?
          </h2>
          <p>
            Ainda não, estamos iniciando as operações e por enquanto somente um
            grupo selecionado poderá criar os leilões, estamos trabalhando para
            abrir essa funcionalidade para todos os usuários o mais rápido
            possível! Se quiser muito abrir um leilão, mande um e-mail para
            contato@noubee.com e avaliamos cada pedido individualmente.
          </p>
          <h2>Como vou saber que venci um leilão?</h2>
          <p>
            Você pode acompanhar um status de todos os seus lances no seu
            perfil, além disso, quando o leilão acabar vamos anunciar na página
            do leilão e nos meios de contato do usuário.
          </p>
          <h2>Meu lance foi vencedor, como eu resgato meu prêmio?</h2>
          <p>
            Deixa isso com a gente, a Noubee é o intermediário que garante que
            você vai receber o que ganhou.
          </p>
          <h2>Fiz um leilão, quanto vou receber?</h2>
          <p>
            Depende de quanto movimento seu leilão tem, por isso, divulgue
            bastante para o mundo inteiro ver. Não existe limites de ganho para
            o seu leilão.
          </p>
          <h2>A Noubee cobra taxas?</h2>
          <p>
            Somos extremamente transparentes quando o assunto é o seu dinheiro.
            A Noubee cobra uma taxa operacional que já engloba todos os custos
            de gerenciamento do leilão e processamento de pagamento.
          </p>
          <h2>Quem paga as taxas?</h2>
          <p>
            As taxas são cobradas do montante do leilão, não há custo para os
            usuários que derem lance além do valor do lance. Todos os valores
            serão compartilhados com todos os detalhes para o dono do leilão.
          </p>
          <h2>Ainda tem dúvidas ou está com problemas?</h2>
          <p>
            • Entre em contato conosco por qualquer canal oficial, vamos fazer o
            possível para responder suas dúvidas.
            <p>contato@noubee.com</p>
            <p>@noubee no instagram</p>
          </p>
        </S.Questions>
      </Card>
    </S.Container>
  );
}
