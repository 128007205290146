import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import clock from '../../assets/clock.png';
import { Button } from '../Button';

import * as S from './styles';

type AuctionInfo = {
  active: boolean;
  auctioneer: Auctioneer;
  auctioneer_id: number;
  category: 'Product' | 'Service';
  charity: boolean;
  charity_link: string;
  description: string;
  end_date: Date;
  featured: boolean;
  fee: number;
  head_image: string;
  id: number;
  images: string[];
  start_date: Date;
  title: string;
};

type Auctioneer = {
  id: number;
  public_id: string;
  username: string;
  instagram?: string;
};

type ContentBannerProps = {
  auction: AuctionInfo;
};

export function ContentBanner({ auction }: ContentBannerProps) {
  const history = useHistory();
  const [timeToEnding, setTimeToEnding] = useState(
    intervalToDuration({
      start: new Date(),
      end: new Date(auction.end_date),
    }),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeToEnding(
        intervalToDuration({
          start: new Date(),
          end: new Date(auction.end_date),
        }),
      );
    }, 1000 * 60 * 1); // 1 minuto para atualizar o relógio no card
    return () => clearInterval(intervalId);
  }, [timeToEnding, auction]);

  const daysTotal =
    (timeToEnding?.months ?? 0) * 30 + (timeToEnding?.days ?? 0);

  function navigateToAuctionPage() {
    history.push(`/leilao/${auction.id}`);
  }

  return (
    <S.Banner>
      {/* <S.AvatarOrganizer src={auction.head_image} alt="Alt desta imagem" /> */}
      <S.BackgroundImage background={auction?.head_image} />
      <S.ContentBanner>
        <S.TimeToEnding>
          <img src={clock} alt="ícone relógio" className="clock-icon" />
          <span>{`${daysTotal} dia(s) : ${timeToEnding.hours} hora(s) : ${timeToEnding.minutes} min(s)`}</span>
        </S.TimeToEnding>
        <S.Title>{auction?.title}</S.Title>
        <S.Description>{auction?.description}</S.Description>
        <Button size="medium" onClick={navigateToAuctionPage}>
          Saiba mais
        </Button>
      </S.ContentBanner>
    </S.Banner>
  );
}
