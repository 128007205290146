import { AxiosPromise } from 'axios';
import api from '../config/api';

type AuctionProps = {
  title: string;
  description: string;
  category: number;
  start_date: string;
  end_date: string;
  fee: number;
  charity_link?: string;
  charity_share?: number;
  head_image: string;
  images: string[];
};

export const getAllAuctions = (): AxiosPromise => api.get('/auctions/all');

export const getAuction = (id: number): AxiosPromise =>
  api.get(`/auctions/${id}`);

export const getBidsInAuction = (id: number, token: string) =>
  api.get(`/auctions/${id}/bid`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

export const getAllBids = (token: string) =>
  api.get(`/bids/all`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

export const createAuction = (
  data: AuctionProps,
  token: string,
): AxiosPromise =>
  api.post('/auctions', data, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

export const bidAnAuction = (
  amount: number,
  id: number,
  token: string,
): AxiosPromise =>
  api.post(
    `/auctions/${id}/bid`,
    { value: amount },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
  );
