import styled, { css } from 'styled-components';

export const Container = styled.div`
  color: #fff;
  padding: 4.4rem 0;
  margin: 0 auto;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: calc(2 * ${theme.font.sizes.xxlarge});
    font-weight: ${theme.font.normal};
    text-align: center;
    margin-bottom: ${theme.spacings.small};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 44rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      span {
        margin-top: ${theme.spacings.xxsmall};
        color: red;
        display: block;
        text-align: center;
      }
    }

    .input-container {
      margin-bottom: ${theme.spacings.xxsmall};
    }

    a {
      color: ${theme.colors.text};
      text-decoration: none;
      margin: 1.6rem 0;
      font-family: 'Roboto', sans-serif;
      font-weight: ${theme.font.bolder};
      margin-top: ${theme.spacings.medium};

      &.forgot-password {
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.normal};
        margin-bottom: ${theme.spacings.small};
        margin-top: ${theme.spacings.xsmall};
      }
    }
  `}
`;
