import axios from 'axios';
import { apiUrl } from '../services/constants';

const request = axios.create({});

const jwtRequiredList: string[] = [];

request.interceptors.request.use(
  config => {
    config.baseURL = apiUrl;
    const token = localStorage.getItem('@Noubee:token');

    const { url } = config;
    const checkJwt = jwtRequiredList.findIndex(jwt => url?.includes(jwt));

    if (checkJwt >= 0 && token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=utf-8',
      };
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default request;
