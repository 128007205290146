import { Link } from 'react-router-dom';

import { FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa';
import * as S from './styles';

import logo from '../../assets/logo.svg';

export function Footer() {
  return (
    <S.Container>
      <Link to="/">
        <img src={logo} alt="Logotipo Noubee" />
      </Link>
      <S.Content>
        <div className="column">
          <p>© 2022 — 2023</p>
          {/* <Link to="/privacidade">Privacidade</Link>
          <Link to="/termos">Termos de uso</Link> */}
        </div>
        <div className="column">
          <Link to="/sobre-nos">Sobre nós</Link>
          <Link to="/como-funciona">Como funciona?</Link>
          <Link to="/faq">FAQ</Link>
        </div>
        <div className="column">
          <a
            href="https://instagram.com/_noubee"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
            Siga nosso Instagram
          </a>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
            Whatsapp
          </a>
          <a
            href="mailto:contato@noubee.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
            E-mail
          </a>
        </div>
      </S.Content>
    </S.Container>
  );
}
