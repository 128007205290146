import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiLock, FiUser } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/auth';
import { schemaSignIn } from '../../pages/SignIn/schemaValidation';
import { Button } from '../Button';
import { Input } from '../Input';

import * as S from './styles';

interface SignInForm {
  email: string;
  password: string;
}

export function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>({
    resolver: yupResolver(schemaSignIn),
  });

  const { signIn } = useAuth();

  const onSubmitSignIn = handleSubmit(async (userCredentials: SignInForm) => {
    setIsLoading(true);
    try {
      await signIn(userCredentials);
    } catch (error) {
      toast.error('Usuário ou senha inválidos.');
    }
    setIsLoading(false);
  });

  return (
    <S.Container onSubmit={onSubmitSignIn}>
      <h2>Por favor, faça o login para efetuar o lance</h2>
      <Input
        icon={FiUser}
        error={errors.email?.message ?? null}
        placeholder="email"
        {...register('email')}
        fullWidth
      />
      <span>{errors.email?.message}</span>
      <div className="input-spacing">
        <Input
          icon={FiLock}
          placeholder="password"
          type="password"
          {...register('password')}
          error={errors.password?.message ?? null}
          fullWidth
        />
      </div>
      <span>{errors.password?.message}</span>

      <Button
        size="fullWidth"
        isLoading={isLoading}
        disabled={isLoading}
        type="submit"
      >
        Login
      </Button>
    </S.Container>
  );
}
