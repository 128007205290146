import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Input } from '../../components/Input';

import * as S from './styles';
import { schemaResetPassword } from './schemaValidation';
import { useAuth } from '../../hooks/auth';
import { lostPasswordRequest } from '../../services/auth';

interface LostPasswordForm {
  email: string;
}

export function LostPassword() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LostPasswordForm>({
    resolver: yupResolver(schemaResetPassword),
  });
  const { signIn, user } = useAuth();

  const onSubmitLostPassword = handleSubmit(
    async (userCredentials: LostPasswordForm) => {
      setIsLoading(true);
      try {
        await lostPasswordRequest(userCredentials);
        toast.info(
          'Enviaremos um e-mail com as instruções para uma nova senha.',
        );
        history.push('/');
      } catch (error) {
        toast.error('Nenhuma conta associada ao e-mail informado.');
      }
      setIsLoading(false);
    },
  );

  return (
    <S.Container>
      <Card>
        <S.Content>
          <S.Title>Esqueci minha senha</S.Title>
          <form onSubmit={onSubmitLostPassword}>
            <div className="input-spacing">
              <Input
                icon={FiMail}
                placeholder="email"
                type="email"
                {...register('email')}
                error={errors.email?.message ?? null}
              />
            </div>

            <div className="input-spacing" />

            <Button
              size="fullWidth"
              isLoading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Recuperar senha
            </Button>
          </form>
        </S.Content>
      </Card>
    </S.Container>
  );
}
