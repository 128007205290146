/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiCamera, FiX } from 'react-icons/fi';

import { IoLogoUsd } from 'react-icons/io';
import { Button } from '../../../components/Button';
import { DatePicker } from '../../../components/DatePicker';
import { Input } from '../../../components/Input';
import { InputMask } from '../../../components/InputMask';
import { Radio } from '../../../components/Radio';
import { Select } from '../../../components/Select';
import { TextArea } from '../../../components/TextArea';
import { schemaNewAuction } from './schemaValidation';

import * as S from './styles';

type NewAuctionForm = {
  title: string;
  category: number;
  startDate: Date;
  startHour: string;
  endDate: Date;
  endHour: string;
  minPrice: string;
  description: string;
  mainImage: File[];
  secondImage: File[];
  thirdImage: File[];
};

type AuctionInfoProps = {
  title: string;
  description: string;
  category: number;
  start_date: string;
  end_date: string;
  fee: number;
  secondImage: File[];
  thirdImage: File[];
  mainImage: File[];
};

const hourOptions = [
  { id: '00h', value: '00:00:00', label: '00:00:00' },
  { id: '01h', value: '01:00:00', label: '01:00:00' },
  { id: '02h', value: '02:00:00', label: '02:00:00' },
  { id: '03h', value: '03:00:00', label: '03:00:00' },
  { id: '04h', value: '04:00:00', label: '04:00:00' },
  { id: '05h', value: '05:00:00', label: '05:00:00' },
  { id: '06h', value: '06:00:00', label: '06:00:00' },
  { id: '07h', value: '07:00:00', label: '07:00:00' },
  { id: '08h', value: '08:00:00', label: '08:00:00' },
  { id: '09h', value: '09:00:00', label: '09:00:00' },
  { id: '10h', value: '10:00:00', label: '10:00:00' },
  { id: '11h', value: '11:00:00', label: '11:00:00' },
  { id: '12h', value: '12:00:00', label: '12:00:00' },
  { id: '13h', value: '13:00:00', label: '13:00:00' },
  { id: '14h', value: '14:00:00', label: '14:00:00' },
  { id: '15h', value: '15:00:00', label: '15:00:00' },
  { id: '16h', value: '16:00:00', label: '16:00:00' },
  { id: '17h', value: '17:00:00', label: '17:00:00' },
  { id: '18h', value: '18:00:00', label: '18:00:00' },
  { id: '19h', value: '19:00:00', label: '19:00:00' },
  { id: '20h', value: '20:00:00', label: '20:00:00' },
  { id: '21h', value: '21:00:00', label: '21:00:00' },
  { id: '22h', value: '22:00:00', label: '22:00:00' },
  { id: '23h', value: '23:00:00', label: '23:00:00' },
];

type PrimaryInfoProps = {
  nextPage: () => void;
  setAuctionInfo: Dispatch<SetStateAction<AuctionInfoProps>>;
};

export function PrimaryInfo({ nextPage, setAuctionInfo }: PrimaryInfoProps) {
  const [mainImage, setMainImage] = useState<FileList | null>(null);
  const [secondImage, setSecondImage] = useState<FileList | null>(null);
  const [thirdImage, setThirdImage] = useState<FileList | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewAuctionForm>({
    resolver: yupResolver(schemaNewAuction),
  });

  const onSubmitNewAuction = handleSubmit((auctionInfo: NewAuctionForm) => {
    auctionInfo.category = Number(auctionInfo.category);
    const start_date = `${auctionInfo.startDate} ${auctionInfo.startHour}`;
    const end_date = `${auctionInfo.endDate} ${auctionInfo.endHour}`;
    const fee = Number(
      auctionInfo.minPrice.replace(/[,.]/, '').replace(',', ''),
    );

    const newAuctionData = {
      title: auctionInfo.title,
      description: auctionInfo.description,
      category: auctionInfo.category,
      start_date,
      end_date,
      fee,
      mainImage: auctionInfo.mainImage,
      secondImage: auctionInfo.secondImage,
      thirdImage: auctionInfo.thirdImage,
    };

    setAuctionInfo(newAuctionData);

    nextPage();
  });

  return (
    <S.Container>
      <form onSubmit={onSubmitNewAuction}>
        <div className="content">
          <div className="column">
            <S.InputContainer>
              <label htmlFor="title">Título</label>
              <Input
                id="title"
                placeholder="Título do leilão"
                {...register('title')}
                error={errors.title?.message ?? null}
                fullWidth
              />
              <span>{errors.title?.message}</span>
            </S.InputContainer>

            <S.InputRadioContainer>
              <p>Categoria</p>

              <div className="radios">
                {/* <div className="radio-container">
                  <Radio
                    id="product"
                    {...register('category')}
                    value={0}
                    defaultChecked
                  />
                  <label htmlFor="product">Produto</label>
                </div> */}

                <div className="radio-container">
                  <Radio
                    id="service"
                    {...register('category')}
                    value={1}
                    defaultChecked
                  />
                  <label htmlFor="service">Serviço</label>
                </div>
              </div>
              <span className="error">{errors.category?.message}</span>
            </S.InputRadioContainer>

            <S.InputsDateInfos>
              <S.InputContainer>
                <label htmlFor="endDate">Data Inicial</label>
                <DatePicker
                  type="date"
                  min={String(new Date())}
                  id="startDate"
                  {...register('startDate')}
                />
                <span>{errors.startDate?.message}</span>
              </S.InputContainer>

              <S.InputContainer>
                <label htmlFor="startHour">Hora Inicial</label>
                <Select
                  {...register('startHour')}
                  id="startHour"
                  options={hourOptions}
                />

                <span>{errors.endHour?.message}</span>
              </S.InputContainer>
            </S.InputsDateInfos>

            <S.InputsDateInfos>
              <S.InputContainer>
                <label htmlFor="endDate">Data final</label>
                <DatePicker
                  type="date"
                  min={String(new Date())}
                  id="endDate"
                  {...register('endDate')}
                />
                <span>{errors.endDate?.message}</span>
              </S.InputContainer>

              <S.InputContainer>
                <label htmlFor="endHour">Hora final</label>
                <Select
                  {...register('endHour')}
                  id="endHour"
                  options={hourOptions}
                />

                <span>{errors.endHour?.message}</span>
              </S.InputContainer>
            </S.InputsDateInfos>

            <S.InputContainer>
              <label htmlFor="price">Valor mínimo (R$)</label>
              <InputMask
                id="price"
                placeholder="Valor mínimo"
                {...register('minPrice')}
                error={errors.minPrice?.message ?? null}
                icon={IoLogoUsd}
                fullWidth
              />
              <span>{errors.minPrice?.message}</span>
            </S.InputContainer>

            <S.InputContainer>
              <label htmlFor="description">Descrição</label>
              <TextArea
                id="description"
                placeholder="Breve descrição do leilão"
                {...register('description')}
                error={errors.description?.message ?? null}
              />
              <span>{errors.description?.message}</span>
            </S.InputContainer>
          </div>

          <div className="column">
            <S.InputContainer>
              {mainImage && (
                <div className="photo main">
                  <FiX onClick={() => setMainImage(null)} />
                  <img
                    src={URL.createObjectURL(mainImage?.[0])}
                    alt="not fount"
                  />
                </div>
              )}

              <label
                htmlFor="mainImage"
                className={`${mainImage ? 'file main none' : 'file main '}`}
              >
                <FiCamera />
              </label>
              <input
                type="file"
                multiple={false}
                id="mainImage"
                {...register('mainImage')}
                accept="image/png, image/jpeg"
                onChange={event => setMainImage(event.target.files)}
              />

              <span>{errors.mainImage?.message}</span>
            </S.InputContainer>

            <S.SecondaryPhotos>
              <S.InputContainer>
                {thirdImage && (
                  <div className="photo secondary">
                    <FiX onClick={() => setThirdImage(null)} />
                    <img
                      src={URL.createObjectURL(thirdImage?.[0])}
                      alt="not fount"
                    />
                  </div>
                )}

                <label
                  htmlFor="thirdImage"
                  className={`${
                    thirdImage ? 'file secondary none' : 'file secondary '
                  }`}
                >
                  <FiCamera />
                </label>
                <input
                  type="file"
                  multiple={false}
                  id="thirdImage"
                  {...register('thirdImage')}
                  accept="image/png, image/jpeg"
                  onChange={event => setThirdImage(event.target.files)}
                />

                <span>{errors.thirdImage?.message}</span>
              </S.InputContainer>

              <S.InputContainer>
                {secondImage && (
                  <div className="photo secondary">
                    <FiX onClick={() => setSecondImage(null)} />
                    <img
                      src={URL.createObjectURL(secondImage?.[0])}
                      alt="not fount"
                    />
                  </div>
                )}

                <label
                  htmlFor="secondImage"
                  className={`${
                    secondImage ? 'file secondary none' : 'file secondary '
                  }`}
                >
                  <FiCamera />
                </label>
                <input
                  type="file"
                  multiple={false}
                  id="secondImage"
                  {...register('secondImage')}
                  accept="image/png, image/jpeg"
                  onChange={event => setSecondImage(event.target.files)}
                />

                <span>{errors.secondImage?.message}</span>
              </S.InputContainer>
            </S.SecondaryPhotos>
          </div>
        </div>

        <div className="button">
          <Button secondary type="submit">
            Próximo
          </Button>
        </div>
      </form>
    </S.Container>
  );
}
