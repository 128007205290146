import { Card } from '../../components/Card';
import * as S from './styles';

export function HowItWorks() {
  return (
    <S.Container>
      <Card>
        <h1>Como funciona</h1>
        <ul>
          <li>
            Cada lance é composto de um valor mínimo estipulado pelo criador do
            leilão e um incremento escolhido pelo usuário.
          </li>
          <li>
            Ao dar o lance apenas o valor mínimo é cobrado do usuário. Caso o
            lance seja o vencedor, o incremento do lance será cobrado.
          </li>
          <li>Não há limites de lances por leilão ou por usuário.</li>
          <li>Os lances serão aceitos somente durante o período do leilão.</li>
          <li>
            O lance vencedor é deverá obedecer 2 critérios:
            <ol>
              <li>Ser o único lance no valor do incremento.</li>
              <li>Ser o menor incremento.</li>
            </ol>
          </li>
          <li>Caso haja empate o leilão será estendido.</li>
          <li>
            O vencedor é decidido automaticamente ao fim do período do leilão.
          </li>
        </ul>

        <p>Exemplo:</p>
        <ul>
          <li>Leilão: Valor mínimo 5 reais.</li>
          <li>Visualizando os lances:</li>
        </ul>

        <table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Valor do lance</th>
              <th>Status</th>
              <th>Motivo</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Usuário 1</td>
              <td>5,01</td>
              <td>Empate</td>
              <td>Lance no mesmo preço pelo Usuário 2</td>
            </tr>
            <tr>
              <td>Usuário 2</td>
              <td>5,01</td>
              <td>Empate</td>
              <td>Lance no mesmo preço pelo Usuário 1</td>
            </tr>
            <tr>
              <td>Usuário 3</td>
              <td>5,07</td>
              <td>Empate</td>
              <td>Lance no mesmo preço pelo Usuário 4</td>
            </tr>
            <tr>
              <td>Usuário 4</td>
              <td>5,07</td>
              <td>Empate</td>
              <td>Lance no mesmo preço pelo Usuário 3</td>
            </tr>
            <tr>
              <td>Usuário 5</td>
              <td>5,20</td>
              <td>Vencedor</td>
              <td>Menor lance único</td>
            </tr>
            <tr>
              <td>Usuário 6</td>
              <td>5,22</td>
              <td>Desqualificado</td>
              <td>Lance único, porém, não é o menor</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </S.Container>
  );
}
