import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiLock } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Input } from '../../components/Input';

import * as S from './styles';
import { schemaResetPassword } from './schemaValidation';
import { useAuth } from '../../hooks/auth';
import { resetPasswordRequest } from '../../services/auth';

interface ResetPasswordForm {
  password: string;
  password_confirmation: string;
}

export function ResetPassword() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver(schemaResetPassword),
  });

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user, history]);

  const onSubmitResetPassword = handleSubmit(
    async (userCredentials: ResetPasswordForm) => {
      setIsLoading(true);
      try {
        await resetPasswordRequest(userCredentials);
        history.push('/login');
        toast.info('Senha alterada com sucesso! Por favor efetue seu login.');
      } catch (error) {
        toast.error('Usuário ou senha inválidos.');
      }
      setIsLoading(false);
    },
  );

  return (
    <S.Container>
      <Card>
        <S.Content>
          <S.Title>Bem-vindo ao Noubee</S.Title>
          <form onSubmit={onSubmitResetPassword}>
            <div className="input-spacing">
              <Input
                icon={FiLock}
                placeholder="password"
                type="password"
                {...register('password')}
                error={errors.password?.message ?? null}
              />
            </div>
            <div className="input-spacing">
              <Input
                icon={FiLock}
                placeholder="password confirmation"
                type="password"
                {...register('password_confirmation')}
                error={errors.password_confirmation?.message ?? null}
              />
            </div>

            <div className="input-spacing" />

            <Button
              size="fullWidth"
              isLoading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Alterar senha
            </Button>
          </form>
        </S.Content>
      </Card>
    </S.Container>
  );
}
