import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxlarge};
    font-family: 'Roboto', sans-serif;

    div.header-info {
      max-width: 45%;
      width: 90%;

      .charity {
        display: flex;
        align-items: center;

        label {
          font-size: ${theme.font.sizes.xlarge};
          margin-left: ${theme.spacings.xxsmall};
        }
      }

      p {
        font-size: ${theme.font.sizes.medium};
        font-weight: normal;
        margin: ${theme.spacings.small} 0;
        text-align: start;
      }
    }

    form {
      margin-top: ${theme.spacings.medium};
      display: flex;
      justify-content: space-between;

      div.column-form {
        width: 100%;

        label {
          display: block;
          margin-top: ${theme.spacings.medium};
          margin-bottom: ${theme.spacings.xxsmall};
          font-weight: ${theme.font.bold};
        }

        select {
          width: 90%;
          height: 5.5rem;
          padding: 0 ${theme.spacings.small};
          border-radius: ${theme.border.radius};
          background: ${theme.colors.white};
          font-size: ${theme.font.sizes.large};
        }

        div.final-price {
          height: 5.5rem;
          width: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${theme.colors.white};
          border-radius: ${theme.border.radius};
          font-weight: ${theme.font.bold};

          span {
            margin: 0 0.5rem;
            color: ${theme.colors.secondary};
          }
        }
      }
    }
  `}
`;

export const Buttons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacings.xlarge};

    button + button {
      margin-left: ${theme.spacings.xsmall};
    }
  `}
`;
