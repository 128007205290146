import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import charityBG from '../../assets/charity-background.png';

interface PhotoOrganizerProps {
  background: string;
}

interface GalleryPhotos {
  mainPhoto: string;
  secondaryPhoto: string;
  tertiaryPhoto: string;
}

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} 0;
    display: flex;
    flex-direction: column;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacings.medium};
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  `}
`;

export const MainInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 90%;
    width: 800px;
    padding: 30px 65px;
    margin-right: ${theme.spacings.xlarge};
    background: linear-gradient(
      to top,
      ${theme.colors.secondary},
      ${theme.colors.primary}
    );
    position: relative;

    .auction-info {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: ${theme.spacings.small};

      .auction-info {
        flex-direction: column;
      }
    }
  `}
`;

export const TextInfo = styled.div`
  ${({ theme }) => css`
    h1 {
      font-size: calc(2 * ${theme.font.sizes.xxlarge});
      font-weight: ${theme.font.normal};
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    p {
      max-width: 440px;
      width: 80%;
      font-size: ${theme.font.sizes.small};
      line-height: 140%;
      font-family: 'Roboto', sans-serif;
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

export const PhotoOrganizer = styled.div<PhotoOrganizerProps>`
  ${({ background }) => css`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-filter: saturate(0%);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    span {
      width: 92%;
      display: block;
      text-align: center;
      background: #000;
      margin-bottom: 20px;
      border-bottom-right-radius: 40px;
      border-bottom-left-radius: 40px;

      a {
        color: #fff;
      }
    }
  `}
`;

export const ContentCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 34rem;

    form {
      display: flex;
      flex-direction: column;
    }

    h2 {
      text-align: center;
      font-weight: ${theme.font.normal};
      font-size: calc(2 * ${theme.font.sizes.large});
    }

    p {
      font-family: 'Robobo', sans-serif;
      text-align: center;
      margin: ${theme.spacings.xxsmall};
      font-family: 'Roboto', sans-serif;
    }

    div.purple-info {
      font-family: 'Robobo', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${theme.colors.primary};
      padding: ${theme.spacings.xsmall};
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.bold};
      margin: ${theme.spacings.xxsmall} 0;

      svg {
        margin-right: ${theme.spacings.xxsmall};
      }
    }

    div.auction-value {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: ${theme.colors.primary};
        font-size: calc(2 * ${theme.font.sizes.large});
        margin: 0 ${theme.spacings.xxsmall};
      }

      input {
        padding: ${theme.spacings.xsmall};
        border: 3px solid ${theme.colors.primary};
        border-radius: none;
      }
    }

    span.error {
      display: flex;
      justify-content: center;
      color: red;
    }

    div.current-bid {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: ${theme.font.sizes.xxlarge};
      text-align: center;
      margin: ${theme.spacings.xsmall} 0;

      .value {
        font-size: calc(2 * ${theme.font.sizes.large});
      }
    }

    div.card-element {
      background: #fff;
      padding: 16px 4px;
      border-radius: 4px;
      margin-bottom: 16px;
    }
  `}
`;

export const AuctionPhotos = styled.div<GalleryPhotos>`
  ${({ theme, mainPhoto, secondaryPhoto, tertiaryPhoto }) => css`
    margin-top: ${theme.spacings.xsmall};
    display: flex;
    height: 300px;

    .carousel {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;
    }

    .main-photo {
      background-image: url(${mainPhoto});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 60%;

      button {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
      }
    }

    .secondary-photos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      margin-left: ${theme.spacings.medium};

      .secondary-photo {
        height: 45%;
        width: 100%;
        background-image: url(${secondaryPhoto});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        button {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
        }
      }

      .tertiary-photo {
        height: 45%;
        width: 100%;
        background-image: url(${tertiaryPhoto});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        button {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
        }
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      min-height: 800px;

      .main-photo {
        width: 100%;
        height: 300px;
      }

      .secondary-photos {
        margin-left: 0;

        .secondary-photo {
          width: 100%;
          height: 250px;
          margin: ${theme.spacings.xsmall} 0;
        }
        .tertiary-photo {
          width: 100%;
          height: 250px;
        }
      }
    }
  `}
`;

export const CharityContainer = styled.div`
  ${({ theme }) => css`
    height: 225px;
    background-image: url(${charityBG});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${theme.spacings.xxlarge};
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    h1 {
      color: ${theme.colors.secondary};
      font-weight: ${theme.font.normal};
      font-size: calc(2 * ${theme.font.sizes.xlarge});
    }

    p {
      width: 90%;
      max-width: 600px;
    }
  `}
`;

export const ContainerGallery = styled(Carousel)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      height: 100%;
      flex: 1;

      svg {
        font-size: ${theme.font.sizes.xxlarge};
        position: absolute;
        right: 30px;
        top: 10px;
        cursor: pointer;
        transition: filter 0.2s;
        background: #0008;
        border-radius: 50%;
        padding: 1px;
        z-index: 5;

        &:hover {
          filter: brightness(80%);
        }
      }

      img {
        height: 100%;
        flex: 1;
      }
    }
  `}
`;

export const BidsInThisAuction = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} 0;

    h2 {
      color: ${theme.colors.primary};
      font-weight: normal;
      font-size: 5.4rem;
    }

    table {
      width: 100%;
      font-family: 'Roboto', sans-serif;
      border-collapse: collapse;
      margin-top: 16px;

      thead {
        font-weight: 600;
        tr {
          th {
            text-align: left;
          }
        }

        th {
          padding: 8px 0;
        }
      }

      tbody {
        td {
          padding: 8px 0;
          border-bottom: 1px solid white;
        }
      }
    }
  `};
`;
