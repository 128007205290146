import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxlarge} 0;
    width: 100%;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .content {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }

      .button {
        display: flex;
        justify-content: center;
      }

      label.file {
        background: #fff;

        svg {
          color: ${theme.colors.secondary};
          font-size: ${theme.font.sizes.xxlarge};
        }

        &.main {
          width: 45rem;
          height: 28rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: ${theme.border.radius};
        }

        &.secondary {
          width: 22rem;
          height: 14rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: ${theme.border.radius};
        }

        &.none {
          display: none;
        }
      }

      div.photo {
        position: relative;

        &.main {
          width: 45rem;
          height: 28rem;
          display: flex;
          flex-wrap: wrap
          align-items: flex-start;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &.secondary {
          width: 22rem;
          height: 14rem;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        svg {
          position: absolute;
          background: #0008;
          font-size: ${theme.font.sizes.xxlarge};
          right: 10px;
          top: 10px;
          cursor: pointer;
          transition: filter 0.3s;

          &:hover {
            filter: brightness(80%);
          }
        }
      }
    }

    @media(max-width: 950px) {
      form {
        .content {
          flex-direction: column;
        }

        label.file {
          width: 100%;

          &.main {
            width: 100%;
            height: 25rem
          }
        }

        div.photo {
          &.main {
            width: 100%;
            height: 20rem;
          }
        }
      }
    }

    @media(max-width: 600px) {
      form {
        .content {
          flex-direction: column;
        }

        label.file {
          width: 100%;

          &.main {
            width: 100%;
            height: 15rem;
          }

          &.secondary {
            max-width: 100%;
            height: 15rem;
          }
        }

        div.photo {
          &.main {
            width: 100%;
            height: 10rem;
          }

          &.main {
            width: 100%;
            height: 15rem;
          }
        }
      }
    }
  `}
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.xxsmall} 0;
    display: relative;

    label {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.bold};
      margin-bottom: ${theme.spacings.xxsmall};
    }

    span {
      display: block;
      margin-top: ${theme.spacings.xxsmall};
      color: red;
    }

    input[type='file'] {
      position: absolute;
      top: 0;
    }
  `}
`;

export const InputRadioContainer = styled.div`
  ${({ theme }) => css`
    > p {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.large};
      font-weight: ${theme.font.bold};
      margin: ${theme.spacings.xxsmall} 0;
    }

    .radios {
      display: flex;
    }

    .radio-container {
      label {
        font-family: 'Roboto', sans-serif;
        font-size: ${theme.font.sizes.medium};
        font-weight: ${theme.font.normal};
        margin-left: ${theme.spacings.xxsmall};
      }

      & + .radio-container {
        margin-left: ${theme.spacings.xxlarge};
      }
    }

    span.error {
      display: block;
      margin-top: ${theme.spacings.xxsmall};
      color: red;
    }
  `}
`;

export const InputsDateInfos = styled.div`
  margin: ${({ theme }) => theme.spacings.xxsmall} 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const SecondaryPhotos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
