import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxlarge};
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .column {
      width: 45%;

      img {
        margin-top: ${theme.spacings.medium};
        margin-bottom: ${theme.spacings.small};
        width: 100%;
      }
    }

    .info-container {
      h2 {
        font-size: ${theme.font.sizes.xlarge};
        margin-bottom: ${theme.spacings.xsmall};
      }

      .input {
        width: 100%;
        background: transparent;
        padding: ${theme.spacings.xsmall};
        border: 1px solid #fff;
        border-radius: ${theme.border.radius};

        p {
          padding: ${theme.spacings.xxsmall} 0;
        }
      }

      div.final-price {
        height: 5.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${theme.colors.white};
        border-radius: ${theme.border.radius};
        font-weight: ${theme.font.bold};

        span {
          margin: 0 0.5rem;
          color: ${theme.colors.secondary};
        }
      }

      & + .info-container {
        margin-top: ${theme.spacings.xsmall};
      }
    }
  `}
`;

export const Buttons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacings.xlarge};

    button + button {
      margin-left: ${theme.spacings.xsmall};
    }
  `}
`;

export const InputRadioContainer = styled.div`
  ${({ theme }) => css`
    .radios {
      display: flex;
    }

    .radio-container {
      label {
        font-family: 'Roboto', sans-serif;
        font-size: ${theme.font.sizes.medium};
        font-weight: ${theme.font.normal};
        margin-left: ${theme.spacings.xxsmall};
      }

      & + .radio-container {
        margin-left: ${theme.spacings.xxlarge};
      }
    }
  `}
`;
