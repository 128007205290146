import styled, { css } from 'styled-components';

export const Container = styled.form`
  ${({ theme }) => css`
    margin: ${theme.spacings.xsmall} 0;

    h2 {
      font-size: ${theme.font.sizes.large};
      margin-bottom: ${theme.spacings.xxsmall};
    }

    .input-spacing {
      margin: ${theme.spacings.xxsmall} 0;
    }
  `}
`;
