/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  forwardRef,
  ForwardRefRenderFunction,
  ComponentType,
  InputHTMLAttributes,
} from 'react';
import { IconBaseProps } from 'react-icons/lib';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | null;
  icon?: ComponentType<IconBaseProps>;
  fullWidth?: boolean;
}

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  requireDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const InputMaskBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { error, icon: Icon, id, fullWidth, ...rest },
  ref,
) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });
  return (
    <S.Container error={!!error} haveIcon={!!Icon} fullWidth={fullWidth}>
      {Icon && <Icon />}
      <MaskedInput
        mask={currencyMask}
        id={id}
        {...rest}
        // @ts-ignore
        ref={ref}
      />
    </S.Container>
  );
};

export const InputMask = forwardRef(InputMaskBase);
