import * as Yup from 'yup';
import { compareDesc, sub } from 'date-fns';

const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

export const schemaNewAuction = Yup.object().shape({
  title: Yup.string().required('Título obrigatório'),
  category: Yup.string().required('Categoria obrigatória'),
  startDate: Yup.string().required('Data obrigatória'),
  startHour: Yup.string().required('Hora obrigatória'),
  endDate: Yup.string().required('Data obrigatória'),
  endHour: Yup.string().required('Hora obrigatória'),
  minPrice: Yup.string().required('Preço obrigatório'),
  description: Yup.string().required('Descrição obrigatória'),
  mainImage: Yup.mixed()
    .test('fileExists', 'Foto obrigatória.', value => {
      return value[0];
    })
    .test(
      'fileFormat',
      'Formato não suportado.',
      value =>
        value[0] &&
        value.length > 0 &&
        SUPPORTED_FORMATS.includes(value[0].type),
    )
    .test('fileSize', 'Tamanho máximo da foto excedido.', value => {
      return value[0] && value[0].size <= 100000000;
    }),
  secondImage: Yup.mixed()
    .test('fileExists', 'Foto obrigatória.', value => {
      return value[0];
    })
    .test(
      'fileFormat',
      'Formato não suportado.',
      value =>
        value[0] &&
        value.length > 0 &&
        SUPPORTED_FORMATS.includes(value[0].type),
    )
    .test('fileSize', 'Tamanho máximo da foto excedido.', value => {
      return value[0] && value[0].size <= 100000000;
    }),
  thirdImage: Yup.mixed()
    .test('fileExists', 'Foto obrigatória.', value => {
      return value[0];
    })
    .test(
      'fileFormat',
      'Formato não suportado.',
      value =>
        value[0] &&
        value.length > 0 &&
        SUPPORTED_FORMATS.includes(value[0].type),
    )
    .test('fileSize', 'Tamanho máximo da foto excedido.', value => {
      return value[0] && value[0].size <= 100000000;
    }),
});
