import styled, { css } from 'styled-components';
import media from 'styled-media-query';

type OptionsMenuProps = {
  isVisible: boolean;
};

export const Container = styled.header`
  ${({ theme }) => css`
    height: 6.4rem;
    width: 100%;
    box-shadow: 0px 2px 10px 2px ${theme.colors.primary};
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background: ${theme.colors.background};
    z-index: ${theme.layers.alwaysOnTop};

    @media (max-width: 753px) {
      .web {
        display: none;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xsmall};

  > a {
    height: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    a {
      color: ${theme.colors.text};
      text-decoration: none;
      font-size: ${theme.font.sizes.xxlarge};

      & + a {
        margin-left: ${theme.spacings.xsmall};
      }
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;

  button + button {
    margin-left: ${({ theme }) => theme.spacings.xsmall};
  }
`;

export const SearchInputContainer = styled.div`
  ${({ theme }) => css`
    width: 30rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 100%;
      height: 4rem;
      border-radius: ${theme.border.radius};
      padding: ${theme.spacings.xxsmall};
      padding-left: ${theme.spacings.medium};
      font-size: ${theme.font.sizes.xlarge};
      border: none;
    }

    svg {
      position: absolute;
      left: ${theme.spacings.xxsmall};
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.xlarge};
    }

    ${media.lessThan('large')`
        width: 20rem;
    `}
  `}
`;

export const ProfileMenu = styled.div`
  position: relative;
`;

export const OptionsMenu = styled.div<OptionsMenuProps>`
  ${({ theme, isVisible }) => css`
    display: flex;
    flex-direction: column;
    opacity: ${isVisible ? '1' : '0'};
    position: absolute;
    width: 100%;
    max-height: ${isVisible ? '300px' : '0'};
    background: linear-gradient(
      to top,
      ${theme.colors.secondary},
      ${theme.colors.primary}
    );
    padding: 0 ${theme.spacings.small};
    overflow: hidden;
    transition: all 0.3s;

    button {
      width: 100%;
      font-family: 'Bebas Neue', cursive;
      background: transparent;
      padding: ${theme.spacings.xxsmall} 0;
      border: none;
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.xxlarge};
      text-align: left;
    }
  `}
`;

export const ContainerOpenedMenu = styled.div<OptionsMenuProps>`
  width: 100%;
  height: 100vh;
  position: fixed;
  background: transparent;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;
