import styled, { css } from 'styled-components';

interface SectionNameProps {
  active: boolean;
}

interface SectionInfoProps {
  isVisible: boolean;
}

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} ${theme.spacings.xsmall};

    @media (min-width: 700px) {
      .mobile {
        display: none;
      }
    }

    @media (max-width: 700px) {
      .web {
        display: none;
      }
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: calc(2 * ${theme.font.sizes.xlarge});
    margin-bottom: ${theme.spacings.medium};
  `}
`;

export const SectionLinks = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 5px solid #fff;
    padding: 0 ${theme.spacings.small} ${theme.spacings.xsmall};

    @media (max-width: 700px) {
      justify-content: center;
    }
  `}
`;

export const SectionName = styled.span<SectionNameProps>`
  ${({ theme, active }) => css`
    border: none;
    display: block;
    background: none;
    color: ${active ? theme.colors.text : '#fff5'};
    font-family: 'Bebas Neue', sans-serif;
    font-size: calc(2 * ${theme.font.sizes.xlarge});
    transition: color 0.3s;
    padding: 0;

    &:not(:first-child) {
      margin-left: ${theme.spacings.small};
    }
  `}
`;

export const ContentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionInfo = styled.section<SectionInfoProps>`
  ${({ isVisible }) => css`
    display: ${isVisible ? 'flex' : 'none'};
  `}
`;
