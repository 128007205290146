import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large} 0;
    width: 100%;

    h1 {
      font-size: ${theme.font.sizes.xxlarge};
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

export const Questions = styled.div`
  ${({ theme }) => css`
    width: 100%;
    font-family: 'Roboto', sans-serif;
    text-align: center;

    h2 {
      font-size: ${theme.font.sizes.large};
      margin-bottom: ${theme.spacings.xsmall};
    }

    p {
      font-size: ${theme.font.sizes.medium};
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;
