import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large} 0;
    width: 100%;

    h1 {
      font-size: ${theme.font.sizes.xxlarge};
      margin-bottom: ${theme.spacings.small};
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.large};
      line-height: 1.5;
      margin-bottom: ${theme.spacings.xsmall};
      margin-top: ${theme.spacings.xsmall};
      width: 90%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      width: 90%;
    }

    ol {
      margin-left: ${theme.spacings.small};
      margin-top: ${theme.spacings.xsmall};
    }

    li {
      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.large};
      margin-bottom: ${theme.spacings.xsmall};
      line-height: 1.5;
    }

    table {
      width: 100%;
      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.large};
    }
  `}
`;
