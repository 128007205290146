import styled, { css } from 'styled-components';

interface InputProps {
  error?: boolean;
}

const wrapperModifiers = {
  error: () => css`
    border: 2px solid red;
  `,
};

export const Container = styled.div<InputProps>`
  ${({ theme }) => css`
    width: 100%;
    height: 5.5rem;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: ${theme.font.sizes.large};
  `}

  ${({ error }) => !!error && wrapperModifiers.error()}
`;

export const SelectComponent = styled.select`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    font-size: ${theme.font.sizes.large};
    padding: 0 ${theme.spacings.small};
  `}
`;
