import styled, { css } from 'styled-components';
import media from 'styled-media-query';

interface AnchorTypeProps {
  active: boolean;
}

interface CardsProps {
  isVisibility: boolean;
  empty: boolean;
}

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large} 0;
  `}
`;

export const Types = styled.div`
  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
  `}
`;

export const AnchorType = styled.button<AnchorTypeProps>`
  ${({ theme, active }) => css`
    border: none;
    background: none;
    color: ${active ? theme.colors.text : '#fff5'};
    font-family: 'Bebas Neue', sans-serif;
    font-size: calc(2 * ${theme.font.sizes.xlarge});
    transition: color 0.3s;
    padding: 0;

    &:not(:first-child) {
      margin-left: ${theme.spacings.small};
    }

    &:hover {
      color: ${theme.colors.text};
    }
  `}

  ${media.lessThan('medium')`
    &:not(:first-child) {
      margin-left: 0;
    }
  `};
`;

export const ProductCards = styled.div<CardsProps>`
  ${({ theme, isVisibility }) => css`
    margin-top: ${theme.spacings.medium};
    display: ${isVisibility ? 'grid' : 'none'};
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.spacings.medium};
    position: relative;

    @media (max-width: 960px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 660px) {
      grid-template-columns: 1fr;
    }
  `}

  ${({ empty, isVisibility }) =>
    empty &&
    css`
      display: ${isVisibility ? 'flex' : 'none'};
    `}
`;

export const ContainerEmptyAuction = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: ${theme.font.sizes.xxlarge};
    color: ${theme.colors.secondary};
  `}
`;
