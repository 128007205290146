import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card';
import { useAuth } from '../../hooks/auth';
import { PaymentInfo } from './PaymentInfo';
import { PrimaryInfo } from './PrimaryInfo';
import { ReviewAuctionInfo } from './ReviewAuctionInfo';
import * as S from './styles';

type AuctionInfoProps = {
  title: string;
  description: string;
  category: number;
  start_date: string;
  end_date: string;
  fee: number;
  mainImage: File[];
  secondImage: File[];
  thirdImage: File[];
  charity_link?: string;
  charity_share?: number;
};

export function NewAuction() {
  const { user } = useAuth();
  const history = useHistory();
  const [sectionActive, setSectionActive] = useState<
    'info' | 'payment' | 'review'
  >('info');
  const [auctionInfo, setAuctionInfo] = useState({} as AuctionInfoProps);

  const sectionName = useMemo(() => {
    if (sectionActive === 'info') {
      return 'Informações';
    }
    if (sectionActive === 'payment') {
      return 'Pagamento';
    }
    return 'Revisão';
  }, [sectionActive]);

  useEffect(() => {
    if (!user.is_auctioneer) {
      history.push('/');
    }
  }, [user, history]);

  return (
    <S.Container>
      <S.Title>Defina seu leilão</S.Title>

      <Card>
        <S.SectionLinks className="web">
          <S.SectionName active={sectionActive === 'info'}>
            Informações
          </S.SectionName>
          <S.SectionName active={sectionActive === 'payment'}>
            Pagamento
          </S.SectionName>
          <S.SectionName active={sectionActive === 'review'}>
            Revisão
          </S.SectionName>
        </S.SectionLinks>

        <S.SectionLinks className="mobile">
          <S.SectionName active>{sectionName}</S.SectionName>
        </S.SectionLinks>

        <S.ContentInfo>
          <S.SectionInfo isVisible={sectionActive === 'info'}>
            <PrimaryInfo
              nextPage={() => setSectionActive('payment')}
              setAuctionInfo={setAuctionInfo}
            />
          </S.SectionInfo>
          <S.SectionInfo isVisible={sectionActive === 'payment'}>
            <PaymentInfo
              nextPage={() => setSectionActive('review')}
              previousPage={() => setSectionActive('info')}
              auctionInfo={auctionInfo}
              setAuctionInfo={setAuctionInfo}
            />
          </S.SectionInfo>
          <S.SectionInfo isVisible={sectionActive === 'review'}>
            <ReviewAuctionInfo
              previousPage={() => setSectionActive('payment')}
              auctionInfo={auctionInfo}
            />
          </S.SectionInfo>
        </S.ContentInfo>
      </Card>
    </S.Container>
  );
}
