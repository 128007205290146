import { format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Radio } from '../../../components/Radio';
import { useAuth } from '../../../hooks/auth';
import { createAuction } from '../../../services/auction';
import * as S from './styles';

type AuctionInfoProps = {
  title: string;
  description: string;
  category: number;
  start_date: string;
  end_date: string;
  fee: number;
  mainImage: File[];
  secondImage: File[];
  thirdImage: File[];
  charity_link?: string;
  charity_share?: number;
};

type ReviewAuctionInfoProps = {
  previousPage: () => void;
  auctionInfo: AuctionInfoProps;
};

export function ReviewAuctionInfo({
  auctionInfo,
  previousPage,
}: ReviewAuctionInfoProps) {
  const noubeeRate = 15;
  const { token, user } = useAuth();
  const history = useHistory();
  const [mainBase64Image, setMainBase64Image] = useState<string>('');
  const [secondBase64Image, setSecondBase64Image] = useState<string>('');
  const [thirdBase64Image, setThirdBase64Image] = useState<string>('');

  if (!user) {
    history.push('/');
  }

  if (Object.keys(auctionInfo).length === 0) {
    return null;
  }

  const handleReaderLoaded1 = (readerEvt: any) => {
    const binaryString = readerEvt.target.result;
    setMainBase64Image(btoa(binaryString));
  };
  const handleReaderLoaded2 = (readerEvt: any) => {
    const binaryString = readerEvt.target.result;
    setSecondBase64Image(btoa(binaryString));
  };
  const handleReaderLoaded3 = async (readerEvt: any) => {
    const binaryString = readerEvt.target.result;
    setThirdBase64Image(btoa(binaryString));
  };

  const file1 = auctionInfo.mainImage?.[0];
  const file1_ext = file1.type.split('/')[1];
  const file2 = auctionInfo.secondImage?.[0];
  const file2_ext = file1.type.split('/')[1];
  const file3 = auctionInfo.thirdImage?.[0];
  const file3_ext = file1.type.split('/')[1];

  const reader = new FileReader();
  reader.onload = handleReaderLoaded1;
  reader.readAsBinaryString(file1);
  const reader2 = new FileReader();
  reader2.onload = handleReaderLoaded2;
  reader2.readAsBinaryString(file2);

  const reader3 = new FileReader();
  reader3.onload = handleReaderLoaded3;
  reader3.readAsBinaryString(file3);

  async function handleSubmitNewAuction() {
    const { mainImage, secondImage, thirdImage, ...rest } = auctionInfo;

    const newAuctionInfo = {
      ...rest,
      head_image: `data:image/${file1_ext};base64,${mainBase64Image}`,
      images: [
        `data:image/${file2_ext};base64,${secondBase64Image}`,
        `data:image/${file3_ext};base64,${thirdBase64Image}`,
      ],
    };

    try {
      await createAuction(newAuctionInfo, token);
      history.push('/');
    } catch (error) {
      toast.error('Não foi possível criar o leilão!');
    }
  }

  return (
    <S.Container>
      <S.Content>
        <div className="column">
          <div className="info-container">
            <h2>Título</h2>
            <div className="input">{auctionInfo?.title}</div>
          </div>

          <div className="info-container">
            <h2>Categoria</h2>
            <S.InputRadioContainer>
              <div className="radios">
                <div className="radio-container">
                  <Radio
                    id="product"
                    value={0}
                    defaultChecked={auctionInfo?.category === 0}
                  />
                  <label htmlFor="product">Produto</label>
                </div>

                <div className="radio-container">
                  <Radio
                    id="service"
                    value={1}
                    defaultChecked={auctionInfo?.category === 1}
                  />
                  <label htmlFor="service">Serviço</label>
                </div>
              </div>
            </S.InputRadioContainer>
          </div>

          <div className="info-container">
            <h2>Data inicial</h2>
            <div className="input">
              {format(
                new Date(auctionInfo?.start_date),
                "dd 'de' MMMM 'de' yyyy', às' HH:mm'h'",
                {
                  locale: ptBR,
                },
              )}
            </div>
          </div>

          <div className="info-container">
            <h2>Data final</h2>
            <div className="input">
              {format(
                new Date(auctionInfo?.end_date),
                "dd 'de' MMMM 'de' yyyy', às' HH:mm'h'",
                {
                  locale: ptBR,
                },
              )}
            </div>
          </div>

          <div className="info-container">
            <h2>Descrição</h2>
            <div className="input">
              <p>{auctionInfo?.description}</p>
            </div>
          </div>
        </div>
        <div className="column">
          <img
            src={URL.createObjectURL(auctionInfo?.mainImage?.[0])}
            alt={`Imagem do leilão ${auctionInfo?.title}`}
          />

          <div className="info-container">
            <h2>Caridade escolhida</h2>
            <div className="input">
              {auctionInfo?.charity_link ?? 'Nenhuma caridade foi escolhida'}
            </div>
          </div>

          <div className="info-container">
            <h2>Preço mínimo final</h2>
            {auctionInfo?.charity_share ? (
              <div className="final-price">
                {(auctionInfo?.fee / 100)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                - <span>{noubeeRate}%</span>-
                <span>{auctionInfo.charity_share}%</span>=
                {(
                  (auctionInfo?.fee / 100 - noubeeRate) *
                  ((100 - auctionInfo.charity_share) / 100)
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
            ) : (
              <div className="final-price">
                {(auctionInfo?.fee / 100)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                - <span>{noubeeRate}%</span>={' '}
                {(
                  auctionInfo?.fee / 100 -
                  (auctionInfo?.fee * (noubeeRate / 100)) / 100
                )?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
            )}
          </div>
        </div>
      </S.Content>

      <S.Buttons>
        <Button onClick={previousPage} secondary>
          Voltar
        </Button>
        <Button onClick={handleSubmitNewAuction}>Finalizar</Button>
      </S.Buttons>
    </S.Container>
  );
}
