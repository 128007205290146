import { Link, useHistory } from 'react-router-dom';
import { FiChevronDown, FiUser } from 'react-icons/fi';
import { useState } from 'react';
import { Button } from '../Button';

import logo from '../../assets/logo.svg';

import * as S from './styles';
import MediaMatch from '../MediaMatch';
import { useAuth } from '../../hooks/auth';

export function Header() {
  const history = useHistory();
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const { user, signOut } = useAuth();

  function handleSignOut() {
    signOut();
    setMenuIsVisible(false);
    history.push('/login');
  }

  function goTo(endpoint: string) {
    setMenuIsVisible(false);
    history.push(`/${endpoint}`);
  }

  return (
    <S.Container>
      <S.ContainerOpenedMenu
        onClick={() => setMenuIsVisible(false)}
        isVisible={menuIsVisible}
      />
      <S.Content>
        <Link to="/">
          <img src={logo} alt="Noubee" />
        </Link>

        <S.Nav className="web">
          <Link to="/sobre-nos">Sobre nós</Link>
          <Link to="/como-funciona">Como funciona</Link>
          <Link to="/faq">FAQ</Link>
        </S.Nav>

        {/* <MediaMatch greaterThan="medium">
          <S.SearchInputContainer>
            <FiSearch />
            <input type="text" />
          </S.SearchInputContainer>
        </MediaMatch> */}

        {user ? (
          <>
            <MediaMatch greaterThan="medium">
              <S.ProfileMenu className="web">
                <Button
                  secondary
                  header
                  size="xsmall"
                  icon={FiUser}
                  type="button"
                  onClick={() => setMenuIsVisible(old => !old)}
                >
                  Meu perfil
                  <FiChevronDown />
                </Button>

                <S.OptionsMenu isVisible={menuIsVisible}>
                  {!!user.is_auctioneer && (
                    <button type="button" onClick={() => goTo('novo-leilao')}>
                      Novo Leilão
                    </button>
                  )}
                  <button type="button" onClick={() => goTo('meus-lances')}>
                    Meus lances
                  </button>
                  <button type="button" onClick={handleSignOut}>
                    Sair
                  </button>
                </S.OptionsMenu>
              </S.ProfileMenu>
            </MediaMatch>

            <MediaMatch lessThan="medium">
              <S.ProfileMenu>
                <Button
                  secondary
                  header
                  size="xsmall"
                  icon={FiUser}
                  type="button"
                  onClick={() => setMenuIsVisible(old => !old)}
                >
                  Meu perfil
                  <FiChevronDown />
                </Button>

                <S.OptionsMenu isVisible={menuIsVisible}>
                  <button type="button" onClick={() => goTo('sobre-nos')}>
                    Sobre Nós
                  </button>
                  <button type="button" onClick={() => goTo('como-funciona')}>
                    Como Funciona
                  </button>
                  <button type="button" onClick={() => goTo('faq')}>
                    FAQ
                  </button>
                  {!!user.is_auctioneer && (
                    <button type="button" onClick={() => goTo('novo-leilao')}>
                      Novo Leilão
                    </button>
                  )}
                  <button type="button" onClick={() => goTo('meus-lances')}>
                    Meus lances
                  </button>
                  <button type="button" onClick={handleSignOut}>
                    Sair
                  </button>
                </S.OptionsMenu>
              </S.ProfileMenu>
            </MediaMatch>
          </>
        ) : (
          <>
            <MediaMatch greaterThan="large">
              <S.ButtonsContainer>
                <Button
                  secondary
                  header
                  size="xsmall"
                  icon={FiUser}
                  type="button"
                  onClick={() => goTo('login')}
                >
                  Login
                </Button>
                <Button
                  secondary
                  header
                  size="xsmall"
                  type="button"
                  onClick={() => goTo('cadastro')}
                >
                  Cadastre-se
                </Button>
              </S.ButtonsContainer>
            </MediaMatch>
            <MediaMatch lessThan="large">
              <Button
                secondary
                size="xsmall"
                className="mobile"
                onClick={() => goTo('login')}
              >
                Entrar
              </Button>
            </MediaMatch>
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
