import { useEffect, useState } from 'react';
import { Banner } from '../../components/Banner';
import { Card } from '../../components/Card';
import { Loader } from '../../components/Loader';
import { ProductCard } from '../../components/ProductCard';
import { getAllAuctions } from '../../services/auction';
import * as S from './styles';

type AuctionInfo = {
  active: boolean;
  auctioneer: Auctioneer;
  auctioneer_id: number;
  category: 'Product' | 'Service';
  charity: boolean;
  charity_link: string;
  description: string;
  end_date: Date;
  featured: boolean;
  fee: number;
  head_image: string;
  id: number;
  images: string[];
  start_date: Date;
  title: string;
};

type Auctioneer = {
  id: number;
  public_id: string;
  username: string;
  instagram?: string;
};

export function Home() {
  const [auctions, setAuctions] = useState<AuctionInfo[]>([]);
  const [productAuctions, setProductAuctions] = useState<AuctionInfo[]>([]);
  const [experienceAuctions, setExperienceAuctions] = useState<AuctionInfo[]>(
    [],
  );
  const [anchorExperiences, setAnchorExperiences] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getAllAuctionsFunc() {
      const response = await getAllAuctions();
      setProductAuctions(
        response.data.filter(
          (currentAuction: AuctionInfo) =>
            currentAuction.category === 'Product',
        ),
      );
      setExperienceAuctions(
        response.data.filter(
          (currentAuction: AuctionInfo) =>
            currentAuction.category === 'Service',
        ),
      );
      setAuctions(response.data);
      setIsLoading(false);
    }

    getAllAuctionsFunc();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <Banner auctions={auctions} />
      <Card>
        <S.Types>
          <S.AnchorType
            active={anchorExperiences}
            onClick={() => setAnchorExperiences(true)}
          >
            Acontecendo agora
          </S.AnchorType>
          {/* <S.AnchorType
            active={!anchorExperiences}
            onClick={() => setAnchorExperiences(false)}
          >
            Produtos
          </S.AnchorType> */}
        </S.Types>

        <S.ProductCards
          isVisibility={anchorExperiences}
          empty={experienceAuctions.length === 0}
        >
          {experienceAuctions.length > 0 &&
            experienceAuctions.map(currentAuction => (
              <ProductCard
                key={currentAuction.id}
                photoURL={currentAuction.head_image}
                dateEnd={new Date(currentAuction.end_date)}
                photoOrganizerURL="https://www.rcm.ac.uk/staffxml/images/90003.jpg"
                price={currentAuction.fee}
                title={currentAuction.title}
                charity={currentAuction.charity}
                id={currentAuction.id}
              />
            ))}
          {experienceAuctions.length === 0 && (
            <S.ContainerEmptyAuction>
              Não há nenhum leilão corrente nesta categoria.
            </S.ContainerEmptyAuction>
          )}
        </S.ProductCards>

        <S.ProductCards
          isVisibility={!anchorExperiences}
          empty={productAuctions.length === 0}
        >
          {productAuctions.length > 0 &&
            productAuctions.map(currentAuction => (
              <ProductCard
                key={currentAuction.id}
                photoURL={currentAuction.head_image}
                dateEnd={new Date(currentAuction.end_date)}
                photoOrganizerURL="https://www.rcm.ac.uk/staffxml/images/90003.jpg"
                price={currentAuction.fee}
                title={currentAuction.title}
                charity={currentAuction.charity}
                id={currentAuction.id}
              />
            ))}
          {productAuctions.length === 0 && (
            <S.ContainerEmptyAuction>
              Não há nenhum leilão corrente nesta categoria.
            </S.ContainerEmptyAuction>
          )}
        </S.ProductCards>
      </Card>
    </S.Container>
  );
}
