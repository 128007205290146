import styled, { css } from 'styled-components';
import media from 'styled-media-query';

interface BackgroundImageProps {
  background?: string;
}

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  height: 38rem;
  position: relative;
`;

export const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  -webkit-filter: saturate(110%) hue-rotate(-45deg) brightness(50%);
  background: url(${({ background }) => background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.layers.base};
`;

export const AvatarOrganizer = styled.img`
  z-index: ${({ theme }) => theme.layers.menu};
  position: absolute;
  right: 43px;
  top: 17px;
  height: 120px;
  width: 120px !important;
  border-radius: 50%;
  -webkit-filter: saturate(0);
  object-fit: cover;

  ${media.lessThan('medium')`
    height: 80px;
    width: 80px !important;
  `}

  ${media.lessThan('small')`
      top: 26rem;
  `}
`;

export const ContentBanner = styled.div`
  height: 100%;
  width: 80rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.4rem;
  z-index: ${({ theme }) => theme.layers.menu};

  button {
    width: 150px;
    margin-left: 6.5rem;
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    button {
      margin-left: 5rem;
    }
  `};
`;

export const TimeToEnding = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    img.clock-icon {
      width: 50px;
      height: 50px;
      margin-right: ${theme.spacings.xxsmall};
    }

    span {
      background-color: ${theme.colors.primary};
      background-image: linear-gradient(
        to top,
        ${theme.colors.secondary},
        ${theme.colors.primary}
      );
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      font-size: 58px;
    }

    ${media.lessThan('medium')`
      span {
        font-size: 44px;
      }

      img.clock-icon {
        width: 40px;
        height: 40px;
        margin-right: ${theme.spacings.xxsmall};
      }
    `}

    ${media.lessThan('small')`
      justify-content: center;

      span {
        font-size: 34px;
      }

      img.clock-icon {
        width: 25px;
        height: 25px;
        margin-right: ${theme.spacings.xxsmall};
      }
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: left;
    margin-left: 6rem;
    font-size: calc(2 * ${theme.font.sizes.xxlarge});
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.lessThan('medium')`
      font-size: 50px;
      margin-left: 5rem;
    `};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-size: ${theme.font.sizes.medium};
    text-align: justify;
    width: 40rem;
    margin-left: 6.5rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.medium};
      margin-left: 5rem;
    `};
  `}
`;
