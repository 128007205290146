import styled, { css } from 'styled-components';

interface InputProps {
  error?: boolean;
}

const wrapperModifiers = {
  error: () => css`
    border: 2px solid red;
  `,
};

export const Container = styled.textarea<InputProps>`
  ${({ theme }) => css`
    width: 100%;
    border-radius: ${theme.border.radius};
    resize: none;
    height: 9.6rem;
    padding: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.medium};
    line-height: 1.5;
  `}

  ${({ error }) => !!error && wrapperModifiers.error()};
`;
