import { Switch, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Container } from './components/Container';
import { useAuth } from './hooks/auth';

import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { ResetPassword } from './pages/ResetPassword';
import { LostPassword } from './pages/LostPassword';
import { Auction } from './pages/Auction';
import { NewAuction } from './pages/NewAuction';
import { Home } from './pages/Home';
import { AboutUs } from './pages/AboutUs';
import { HowItWorks } from './pages/HowItWorks';
import { Faq } from './pages/Faq';
import { MyBids } from './pages/MyBids';

export function Routes() {
  const { publishableKey, user } = useAuth();
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (user) {
      setStripePromise(() => loadStripe(`${publishableKey}`));
    }
  }, [publishableKey, user]);

  if (!publishableKey) {
    <Switch>
      <Container>
        <Route path="/" exact component={Home} />
        <Route path="/login" component={SignIn} />
        <Route path="/cadastro" component={SignUp} />
        <Route path="/leilao/:id" component={Auction} />
        <Route path="/sobre-nos" component={AboutUs} />
        <Route path="/como-funciona" component={HowItWorks} />
        <Route path="/faq" component={Faq} />
        <Route path="/esqueci-minha-senha" component={ResetPassword} />
        <Route path="/esqueci-a-senha" component={LostPassword} />
      </Container>
    </Switch>;
  }

  return (
    <Switch>
      <Elements stripe={stripePromise}>
        <Container>
          <Route path="/" exact component={Home} />
          <Route path="/login" component={SignIn} />
          <Route path="/cadastro" component={SignUp} />
          <Route path="/leilao/:id" component={Auction} />
          <Route path="/novo-leilao" component={NewAuction} />
          <Route path="/meus-lances" component={MyBids} />
          <Route path="/sobre-nos" component={AboutUs} />
          <Route path="/como-funciona" component={HowItWorks} />
          <Route path="/faq" component={Faq} />
          <Route path="/esqueci-minha-senha" component={ResetPassword} />
          <Route path="/esqueci-a-senha" component={LostPassword} />
        </Container>
      </Elements>
    </Switch>
  );
}
