import {
  forwardRef,
  ForwardRefRenderFunction,
  SelectHTMLAttributes,
} from 'react';
import * as S from './styles';

interface OptionsProps {
  value: string;
  label: string;
  id: string;
}

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  error?: string | null;
  options: OptionsProps[];
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, InputProps> = (
  { error, id, options, ...rest },
  ref,
) => {
  return (
    <S.Container error={!!error}>
      <S.SelectComponent id={id} {...rest} ref={ref}>
        {options.map(value => (
          <option value={value.value} key={value.id}>
            {value.label}
          </option>
        ))}
      </S.SelectComponent>
    </S.Container>
  );
};

export const Select = forwardRef(SelectBase);
