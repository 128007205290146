import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { SliderComponent } from '../../../components/Slider';

import * as S from './styles';

type AuctionInfoProps = {
  title: string;
  description: string;
  category: number;
  start_date: string;
  end_date: string;
  fee: number;
  mainImage: File[];
  secondImage: File[];
  thirdImage: File[];
  charity_link?: string;
  charity_share?: number;
};

type PaymentInfoProps = {
  nextPage: () => void;
  previousPage: () => void;
  auctionInfo: AuctionInfoProps;
  setAuctionInfo: Dispatch<SetStateAction<AuctionInfoProps>>;
};

export function PaymentInfo({
  nextPage,
  previousPage,
  auctionInfo,
  setAuctionInfo,
}: PaymentInfoProps) {
  const noubeeRate = 15;
  const [charityLink, setCharityLink] = useState('');
  const [charityShare, setCharityShare] = useState(5);
  const [isCharity, setIsCharity] = useState('0');

  function handleSubmit() {
    if (!Number(isCharity)) {
      nextPage();
      return;
    }

    if (!charityLink) {
      toast.error('Informe a instituição de caridade a ser favorecida.');
    }

    setAuctionInfo(prevState => ({
      ...prevState,
      charity_link: charityLink,
      charity_share: charityShare,
    }));
    nextPage();
  }

  return (
    <S.Container>
      <S.Content>
        <div className="header-info">
          <h2>Como o pagamento funciona:</h2>

          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Perspiciatis consectetur nulla at vel totam tenetur, architecto, est
            facilis quisquam reprehenderit soluta ullam, accusamus odio incidunt
            repellat nisi quaerat praesentium facere.
          </p>

          {/* <div className="charity">
            <input
              type="checkbox"
              name="charity"
              id="charity"
              value={isCharity}
              checked={!!Number(isCharity)}
              onChange={() =>
                setIsCharity(prevState => (prevState === '0' ? '1' : '0'))
              }
            />
            <label htmlFor="charity">Doe para instituições de caridade!</label>
          </div> */}
        </div>

        <form onSubmit={handleSubmit}>
          {/* <div className="column-form">
            <label htmlFor="charity">
              Escolha uma caridade de sua preferência
            </label>
            <Input
              placeholder="Insira a URL da instituição"
              disabled={!Number(isCharity)}
              value={charityLink}
              onChange={e => setCharityLink(e.target.value)}
            />

            <label htmlFor="charity">Porcentagem de doação</label>
            <SliderComponent
              value={charityShare}
              setValue={setCharityShare}
              disabled={Number(isCharity)}
            />
          </div> */}

          <div className="column-form">
            <label htmlFor="final-price">Preço final</label>
            {Number(isCharity) ? (
              <div className="final-price">
                {(auctionInfo?.fee / 100)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                - <span>{noubeeRate}%</span>-<span>{charityShare}%</span>=
                {(
                  auctionInfo?.fee / 100 -
                  (auctionInfo?.fee * (noubeeRate / 100)) / 100
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
            ) : (
              <div className="final-price">
                {(auctionInfo?.fee / 100)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                - <span>{noubeeRate}%</span> ={' '}
                {(
                  auctionInfo?.fee / 100 -
                  (auctionInfo?.fee * (noubeeRate / 100)) / 100
                )?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
            )}
          </div>
        </form>
      </S.Content>

      <S.Buttons>
        <Button onClick={previousPage} secondary>
          Voltar
        </Button>
        <Button type="button" secondary onClick={handleSubmit}>
          Próximo
        </Button>
      </S.Buttons>
    </S.Container>
  );
}
