import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  img {
    margin-bottom: ${({ theme }) => theme.spacings.large};
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.primary};

    a {
      text-decoration: none;
      color: ${theme.colors.primary};
      margin-bottom: ${theme.spacings.xxsmall};
      transition: color 0.3s;

      &:hover {
        color: ${theme.colors.secondary};
      }

      svg {
        margin-right: 8px;
      }
    }

    div.column {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: ${theme.spacings.xxsmall};
      }
    }
  `}
`;
