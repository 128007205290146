import { useState, useEffect, useMemo } from 'react';
import { Duration, format, intervalToDuration } from 'date-fns';
import { FiClock, FiX } from 'react-icons/fi';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import { useParams } from 'react-router';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Loader } from '../../components/Loader';
import { LoginForm } from '../../components/LoginForm';
import { useAuth } from '../../hooks/auth';
import {
  bidAnAuction,
  getAuction,
  getBidsInAuction,
} from '../../services/auction';
import * as S from './styles';
import { createPaymentIntent } from '../../services/payment';
import { InputMask } from '../../components/InputMask';
// FIX-ME
// import { verifyJwtToken } from '../../utils/verifyJwtToken';
import { formatMonetaryValue } from '../../utils/formatMonetaryValue';

type AuctionInfo = {
  active: boolean;
  auctioneer: Auctioneer;
  auctioneer_id: number;
  category: 'Product' | 'Experience';
  charity: boolean;
  charity_link: string;
  description: string;
  end_date: Date;
  featured: boolean;
  fee: number;
  head_image: string;
  id: number;
  images: string[];
  start_date: Date;
  title: string;
};

type Auctioneer = {
  id: number;
  public_id: string;
  username: string;
  instagram?: string;
};

type ParamTypes = {
  id: string;
};

type Fee = {
  fee: string;
};

type BidsInAuction = {
  id: number;
  created_at: Date;
  value: number;
  single: boolean;
};

export function Auction() {
  const { user, token, publishableKey, signOut } = useAuth();
  const { id } = useParams<ParamTypes>();
  const [auction, setAuction] = useState<AuctionInfo>({} as AuctionInfo);
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [viewPhotos, setViewPhotos] = useState(false);
  const [timeToEnding, setTimeToEnding] = useState<Duration>();
  const [bidsInAuction, setBidsInAuction] = useState<BidsInAuction[]>([]);
  const [feeValue, setFeeValue] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const schemaNewFee = Yup.object().shape({
    fee: Yup.string().required('Lance obrigatório'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Fee>({
    resolver: yupResolver(schemaNewFee),
  });

  useEffect(() => {
    async function getAuctionFunc() {
      const response = await getAuction(Number(id));
      setAuction(response.data);

      setTimeToEnding(
        intervalToDuration({
          start: new Date(),
          end: new Date(response.data.end_date),
        }),
      );

      setLoading(false);
    }

    getAuctionFunc();

    async function getUserBidsInAuction() {
      if (!token) {
        return;
      }

      // verifyJwtToken(token, signOut);

      if (!token) {
        toast.error('Sua sessão expirou. Faça o login novamente.');
      }

      // const response = await getBidsInAuction(Number(id), token);
      // setBidsInAuction(response.data);
    }

    getUserBidsInAuction();
  }, [id, token, signOut]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeToEnding(
        intervalToDuration({
          start: new Date(),
          end: new Date(auction.end_date),
        }),
      );
    }, 1000 * 60 * 1); // 1 minuto para atualizar o relógio no card

    return () => clearInterval(intervalId);
  }, [timeToEnding, auction]);

  const images = [
    {
      original: auction?.head_image,
    },
    {
      original: auction?.images?.[0],
    },
    {
      original: auction?.images?.[1],
    },
  ];
  const daysTotal =
    (timeToEnding?.months ?? 0) * 30 + (timeToEnding?.days ?? 0);

  const handleNewFee = handleSubmit(async (feeData: Fee) => {
    setIsSubmiting(true);
    const bidValue = Number(feeData.fee.replace(/[,.]/, '').replace(',', ''));

    if (!stripe || !elements) {
      toast.error('Não foi possível efetuar o pagamento. Tente novamente.');
      setIsSubmiting(false);
      return;
    }

    try {
      const responseBidAnAuction = await bidAnAuction(
        bidValue,
        auction.id,
        token,
      );
      const bidId = responseBidAnAuction.data.id;
      const responsePaymentIntent = await createPaymentIntent(
        {
          cur: 'BRL',
          amount: auction.fee,
          bid_id: bidId,
        },
        token,
      );
      const clientSecretStripe = responsePaymentIntent.data.client_secret;
      stripe.elements({ clientSecret: clientSecretStripe });
      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        stripe
          .confirmCardPayment(clientSecretStripe, {
            payment_method: {
              card: cardElement,
            },
          })
          .then(result => {
            if (result.error) {
              toast.error('Saldo insuficiente!!');
            } else if (result.paymentIntent.status === 'succeeded') {
              toast.success('Pagamento feito com sucesso!');
            }
          });
      }
    } catch (error) {
      toast.error('Não foi possível dar o lance.');
    } finally {
      setIsSubmiting(false);
    }
  });

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        backgroundColor: '#fff',
        color: '#000',
      },
    },
    hidePostalCode: true,
  };

  const currentTotalValue = useMemo(() => {
    const bidValue = Number(feeValue.replace('.', '').replace(',', '.')) ?? 0;

    return (bidValue + auction.fee / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }, [auction.fee, feeValue]);

  const formatFeeValue = useMemo(() => {
    formatMonetaryValue(auction.fee);
  }, [auction]);

  if (loading) {
    return <Loader />;
  }

  return (
    <S.Container>
      <S.Content>
        <S.MainInfo>
          <div className="auction-info">
            <S.TextInfo>
              <h1>{auction?.title}</h1>
              <p>{auction?.description}</p>
            </S.TextInfo>

            {/* <S.PhotoOrganizer background="https://api.inkclub.tattoo/Content/images/tatuadores/273_25_10_2019_negocio-de-tatuagem.jpg">
              <span>
                <a
                  href="http://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{auction?.auctioneer?.instagram}
                </a>
              </span>
            </S.PhotoOrganizer> */}
          </div>

          <S.AuctionPhotos
            mainPhoto={images?.[0].original ?? ''}
            secondaryPhoto={images?.[1].original ?? ''}
            tertiaryPhoto={images?.[2].original ?? ''}
          >
            {!viewPhotos ? (
              <>
                <div className="main-photo">
                  {!viewPhotos && (
                    <button type="button" onClick={() => setViewPhotos(true)}>
                      {' '}
                    </button>
                  )}
                </div>
                <div className="secondary-photos">
                  <div className="secondary-photo">
                    {!viewPhotos && (
                      <button type="button" onClick={() => setViewPhotos(true)}>
                        {' '}
                      </button>
                    )}
                  </div>
                  <div className="tertiary-photo">
                    {!viewPhotos && (
                      <button type="button" onClick={() => setViewPhotos(true)}>
                        {' '}
                      </button>
                    )}
                  </div>
                </div>{' '}
              </>
            ) : (
              <div className="carousel">
                <S.ContainerGallery
                  showArrows
                  showStatus={false}
                  showThumbs={false}
                  swipeable
                  emulateTouch
                >
                  {images.map((image, index) => (
                    <div key={String(`${image.original}-${index}`)}>
                      <FiX onClick={() => setViewPhotos(false)} />
                      <img src={image.original} alt="Foto do produto" />
                    </div>
                  ))}
                </S.ContainerGallery>
              </div>
            )}
          </S.AuctionPhotos>
        </S.MainInfo>

        <Card aside>
          <S.ContentCard>
            <h2>Faça seu lance para:</h2>
            <h2>{auction?.title}</h2>
            <p>Por: {auction?.auctioneer?.username}</p>

            <div className="purple-info">
              <FiClock />
              {`${daysTotal} dia(s) : ${timeToEnding?.hours} hora(s) : ${timeToEnding?.minutes} min(s)`}
            </div>

            {user && publishableKey ? (
              <form onSubmit={handleNewFee}>
                <div className="auction-value">
                  <div className="purple-info">R${formatFeeValue}</div>
                  <span>+</span>
                  <InputMask
                    id="price"
                    placeholder="Informe o valor do lance"
                    fullWidth
                    {...register('fee')}
                    error={errors.fee?.message}
                    value={feeValue}
                    onChange={e => setFeeValue(e.target.value)}
                  />
                </div>
                <span className="error">{errors.fee?.message}</span>
                <div className="current-bid">
                  <span>Lance final:</span>
                  <span className="value">{currentTotalValue}</span>
                </div>
                <div className="card-element">
                  <CardElement options={cardElementOptions} />
                </div>
                <Button
                  type="submit"
                  disabled={!stripe || !elements || isSubmiting}
                  isLoading={isSubmiting}
                >
                  Checkout
                </Button>
              </form>
            ) : (
              <LoginForm />
            )}
          </S.ContentCard>
        </Card>
      </S.Content>

      {auction.charity && (
        <S.CharityContainer>
          <h1>Este leilão apoia uma instituição de caridade</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
            nobis deleniti nisi ut obcaecati adipisci dolores, aspernatur
            reprehenderit maxime delectus ipsa consectetur vel. Rerum facilis
            repellat voluptate officiis explicabo molestias?
          </p>
        </S.CharityContainer>
      )}

      {bidsInAuction.length > 0 && (
        <S.BidsInThisAuction>
          <h2>Lances neste leilão</h2>

          <table>
            <thead>
              <tr>
                <th>Lance</th>
                <th>Data</th>
                <th>Hora</th>
                <th>Valor</th>
                <th>Lance único</th>
              </tr>
            </thead>

            <tbody>
              {bidsInAuction.map(bid => (
                <tr key={bid.id}>
                  <td>#{bid.id}</td>
                  <td>{format(new Date(bid.created_at), 'dd/MM/yyyy')}</td>
                  <td>{format(new Date(bid.created_at), "HH'h'mm")}</td>
                  <td>{formatMonetaryValue(bid.value)}</td>
                  <td>
                    {bid.single ? <AiOutlineLike /> : <AiOutlineDislike />}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </S.BidsInThisAuction>
      )}
    </S.Container>
  );
}
