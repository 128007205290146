import styled, { css } from 'styled-components';

interface CardProps {
  aside: boolean;
}

export const Container = styled.section<CardProps>`
  ${({ theme }) => css`
    &.module-border-wrap {
      max-width: 1200px;
      padding: 0.4rem;
      background: linear-gradient(
        to top,
        ${theme.colors.secondary},
        ${theme.colors.primary}
      );
    }

    .module {
      background: ${theme.colors.background};
      color: white;
      padding: ${theme.spacings.xxlarge};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  `}

  ${({ aside, theme }) =>
    aside &&
    css`
      .module {
        padding: ${theme.spacings.small} ${theme.spacings.xxsmall};
      }
    `}
`;
