import styled, { css, DefaultTheme } from 'styled-components';

interface InputProps {
  error?: boolean;
  haveIcon?: boolean;
  fullWidth?: boolean;
}

const wrapperModifiers = {
  xxsmall: (theme: DefaultTheme) => css`
    height: 2.8rem;
    padding: 0 ${theme.spacings.xsmall};
  `,
  xsmall: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.xsmall};
  `,
  small: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.small};
  `,
  medium: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.medium};
  `,
  large: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.large};
  `,
  fullWidth: (theme: DefaultTheme) => css`
    padding: 0 ${theme.spacings.xxlarge};
    width: 100%;
  `,
  error: () => css`
    border: 2px solid red;
  `,
  haveIcon: (theme: DefaultTheme) => css`
    input {
      padding-left: ${theme.spacings.large};
    }
  `,
};

export const Container = styled.div<InputProps>`
  ${({ theme, fullWidth }) => css`
    height: 5.5rem;
    width: ${fullWidth ? '100%' : '44rem'};
    display: flex;
    align-items: center;
    position: relative;
    border-radius: ${theme.border.radius};

    input {
      width: 100%;
      height: 100%;
      border-radius: ${theme.border.radius};
      border: none;
      padding: ${theme.spacings.small};
      font-size: ${theme.font.sizes.large};
    }

    svg {
      position: absolute;
      left: 1rem;
      height: 100%;
      font-size: ${theme.font.sizes.xlarge};
      color: ${theme.colors.background};
    }

    .password-view-icon {
      position: absolute;
      left: auto;
      right: 2rem;
      cursor: pointer;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(80%);
      }
    }
  `}

  ${({ theme, haveIcon }) => !!haveIcon && wrapperModifiers.haveIcon(theme)}
  ${({ error }) => !!error && wrapperModifiers.error()}
`;
