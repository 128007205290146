import {
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  ComponentType,
  InputHTMLAttributes,
} from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';
import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | null;
  icon?: ComponentType<IconBaseProps>;
  type?: string;
  fullWidth?: boolean;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { error, icon: Icon, type = 'text', fullWidth, ...rest },
  ref,
) => {
  const [viewPasswordText, setViewPasswordtext] = useState(false);

  return (
    <S.Container error={!!error} haveIcon={!!Icon} fullWidth={fullWidth}>
      {Icon && <Icon />}
      {type === 'password' ? (
        <input
          {...rest}
          type={type === 'password' && viewPasswordText ? 'text' : 'password'}
          ref={ref}
        />
      ) : (
        <input {...rest} type={type} ref={ref} />
      )}
      {type === 'password' && viewPasswordText && (
        <FiEyeOff
          className="password-view-icon"
          onClick={() => setViewPasswordtext(old => !old)}
        />
      )}
      {type === 'password' && !viewPasswordText && (
        <FiEye
          className="password-view-icon"
          onClick={() => setViewPasswordtext(old => !old)}
        />
      )}
    </S.Container>
  );
};

export const Input = forwardRef(InputBase);
