import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiLock, FiUser } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Input } from '../../components/Input';

import * as S from './styles';
import { schemaSignIn } from './schemaValidation';
import { useAuth } from '../../hooks/auth';

interface SignInForm {
  email: string;
  password: string;
}

export function SignIn() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>({
    resolver: yupResolver(schemaSignIn),
  });

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user, history]);

  const onSubmitSignIn = handleSubmit(async (userCredentials: SignInForm) => {
    setIsLoading(true);
    try {
      await signIn(userCredentials);
      history.push('/');
    } catch (error) {
      toast.error('Usuário ou senha inválidos.');
    }
    setIsLoading(false);
  });

  function navigateToSignUp() {
    history.push('/cadastro');
  }

  return (
    <S.Container>
      <Card>
        <S.Content>
          <S.Title>Bem-vindo ao Noubee</S.Title>
          <form onSubmit={onSubmitSignIn}>
            <Input
              icon={FiUser}
              error={errors.email?.message ?? null}
              placeholder="email"
              {...register('email')}
            />
            <span>{errors.email?.message}</span>
            <div className="input-spacing">
              <Input
                icon={FiLock}
                placeholder="password"
                type="password"
                {...register('password')}
                error={errors.password?.message ?? null}
              />
            </div>
            <span>{errors.password?.message}</span>
            <Link to="/esqueci-a-senha" className="forgot-password">
              Esqueceu sua senha?
            </Link>

            <Button
              size="fullWidth"
              isLoading={isLoading}
              disabled={isLoading}
              type="submit"
            >
              Login
            </Button>
          </form>
          <h2>Não tem uma conta?</h2>
          <Button
            secondary
            size="fullWidth"
            type="button"
            onClick={navigateToSignUp}
          >
            Cadastre-se
          </Button>
        </S.Content>
      </Card>
    </S.Container>
  );
}
