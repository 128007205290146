import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large} 0;

    h1 {
      font-size: ${theme.font.sizes.xxlarge};
      margin-bottom: ${theme.spacings.small};
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.large};
      text-align: center;
      line-height: 1.5;
      margin-bottom: ${theme.spacings.xxsmall};

      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;
