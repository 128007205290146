import styled, { css } from 'styled-components';

interface ContainerPhotoProps {
  photoURL: string;
}

interface ContainerPhotoAvatarOrganizerProps {
  photoOrganizerURL: string;
}

interface TimeToEndingProps {
  endingSoonIsVisible: boolean;
}

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 290px;
    height: 245px;
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
  `}
`;

export const Photo = styled.div<ContainerPhotoProps>`
  ${({ photoURL }) => css`
    height: 170px;
    width: 100%;
    background: url(${photoURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-filter: saturate(110%) hue-rotate(-45deg);
  `}
`;

export const TimeToEnding = styled.div<TimeToEndingProps>`
  ${({ theme, endingSoonIsVisible }) => css`
    height: 3.5rem;
    background: linear-gradient(
      to top,
      ${theme.colors.secondary},
      ${theme.colors.primary}
    );
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Roboto', sans-serif;
    position: relative;

    svg {
      margin-right: ${theme.spacings.xxsmall};
    }

    .ending-soon {
      position: absolute;
      background: ${theme.colors.secondary};
      width: 100%;
      height: 100%;
      display: ${endingSoonIsVisible ? 'none' : 'flex'};
      justify-content: center;
      align-items: center;
    }
  `}
`;

export const InfoProducts = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${theme.spacings.xsmall};
    color: ${theme.colors.background};
    font-size: ${theme.font.sizes.xlarge};
  `}
`;

export const PhotoOrganizer = styled.div<ContainerPhotoAvatarOrganizerProps>`
  ${({ photoOrganizerURL }) => css`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    background: url(${photoOrganizerURL});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-filter: saturate(0%);
  `}
`;

export const CharityChip = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 2rem;
    left: -4rem;
    text-align: center;
    width: 14rem;
    background: linear-gradient(
      to right,
      ${theme.colors.secondary},
      ${theme.colors.primary}
    );
    font-family: 'Bad Script', cursive;
    transform: rotate(-45deg);
  `}
`;
