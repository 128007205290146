import axios from 'axios';
import { apiUrl } from '../services/constants';

const request = axios.create({});

const jwtRequiredList: string[] = [];

request.interceptors.request.use(
  config => {
    config.baseURL = apiUrl;

    const { url } = config;

    config.headers = {
      'access-token': new URLSearchParams(window.location.search).get(
        'access-token',
      )!,
      uid: new URLSearchParams(window.location.search).get('uid')!,
      client: new URLSearchParams(window.location.search).get('client')!,
    };

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default request;
