import React, { createContext, useCallback, useState, useContext } from 'react';
import {
  signInRequest,
  signUpRequest,
  resetPasswordRequest,
} from '../services/auth';
import { getStripeKey } from '../services/payment';

type User = {
  id: number;
  username: string;
  email: string;
  status: boolean;
  roles: string[];
  public_id: string;
  is_auctioneer?: boolean;
};

type AuthState = {
  token: string;
  user: User;
  publishableKey: string;
};

type SignUpData = {
  username: string;
  password: string;
  email: string;
  phone: string;
};

type SignInCredentials = {
  email: string;
  password: string;
};

type ResetPasswordData = {
  password: string;
  password_confirmation: string;
};

interface AuthContextData {
  user: User;
  token: string;
  publishableKey: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signUp(signUpData: SignUpData): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Noubee:token');
    const user = localStorage.getItem('@Noubee:user');
    const publishableKey = localStorage.getItem('@Noubee:pubKey');

    if (user && token && publishableKey) {
      return { token, user: JSON.parse(user), publishableKey };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async (dataSignIn: SignInCredentials) => {
    const response = await signInRequest(dataSignIn);
    const token = response.headers?.authorization;
    const user = response.data.data;

    const publishableKey = await getStripeKey(token);

    localStorage.setItem('@Noubee:token', token.split(' ')[1]);
    localStorage.setItem('@Noubee:user', JSON.stringify(user));
    localStorage.setItem('@Noubee:pubKey', publishableKey.data.publishable_key);

    setData({
      token,
      user,
      publishableKey: publishableKey.data.publishable_key,
    });
  }, []);

  const signUp = useCallback(async (dataSignUp: SignUpData) => {
    await signUpRequest(dataSignUp);

    // const response = await signInRequest({
    //   email: dataSignUp.email,
    //   password: dataSignUp.password,
    // });

    // const user = response.data.data;
    // const token = response.headers?.authorization;

    // const publishableKey = await getStripeKey(token);

    // localStorage.setItem('@Noubee:token', token);
    // localStorage.setItem('@Noubee:user', JSON.stringify(user));
    // localStorage.setItem('@Noubee:pubKey', JSON.stringify(publishableKey.data));
  }, []);

  const resetPassword = useCallback(
    async (dataResetPassword: ResetPasswordData) => {
      await resetPasswordRequest(dataResetPassword);
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Noubee:token');
    localStorage.removeItem('@Noubee:user');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        publishableKey: data.publishableKey,
        signIn,
        signOut,
        signUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
