import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const Container = styled(Carousel)`
  height: 38rem;
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export const ContainerUnique = styled.div`
  height: 38rem;
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;
