import * as S from './styles';

import { ContentBanner } from '../ContentBanner';

type AuctionInfo = {
  active: boolean;
  auctioneer: Auctioneer;
  auctioneer_id: number;
  category: 'Product' | 'Service';
  charity: boolean;
  charity_link: string;
  description: string;
  end_date: Date;
  featured: boolean;
  fee: number;
  head_image: string;
  id: number;
  images: string[];
  start_date: Date;
  title: string;
};

type Auctioneer = {
  id: number;
  public_id: string;
  username: string;
  instagram?: string;
};

type BannerProps = {
  auctions: AuctionInfo[];
};

export function Banner({ auctions }: BannerProps) {
  if (auctions.length === 0) {
    return null;
  }

  if (auctions.length === 1) {
    return (
      <S.ContainerUnique>
        <ContentBanner auction={auctions[0]} />
      </S.ContainerUnique>
    );
  }

  return (
    <S.Container
      autoPlay
      showArrows={false}
      stopOnHover
      infiniteLoop
      showStatus={false}
      interval={8000}
      showThumbs={false}
      swipeable
      emulateTouch
    >
      {auctions
        .filter(currentAuction => currentAuction.featured === true)
        .map(currentAuction => (
          <ContentBanner auction={currentAuction} key={currentAuction.id} />
        ))}
    </S.Container>
  );
}
