import { ReactNode } from 'react';

import * as S from './styles';

interface CardProps {
  children?: ReactNode;
  aside?: boolean;
}

export function Card({ children, aside = false }: CardProps) {
  return (
    <S.Container className="module-border-wrap" aside={aside}>
      <div className="module">{children}</div>
    </S.Container>
  );
}
