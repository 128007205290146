import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg)
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    height: calc(100vh - 6.4rem);
    background-color: red;
    background: ${theme.colors.background};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(1.5 * ${theme.font.sizes.xxlarge});
    color: ${theme.colors.primary};

    svg {
      animation: ${rotate} 2s linear infinite;
    }
  `}
`;
