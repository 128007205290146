import { createGlobalStyle, css } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  ${({ theme }) => css`
    html {
      font-size: 62.5%;
    }

    body {
      background: ${theme.colors.background};
      color: ${theme.colors.text};
      font-family: 'Bebas Neue', cursive;
      font-size: ${theme.font.sizes.medium};
    }

    button {
      cursor: pointer;
    }
  `}
`;
