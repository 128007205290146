import * as Yup from 'yup';

export const schemaSignUp = Yup.object().shape({
  username: Yup.string().required('Nome de usuário obrigatório'),
  email: Yup.string().email('Email inválido').required('Email obrigatório'),
  password: Yup.string()
    .required('Senha obrigatória')
    .min(6, 'Mínimo 6 dígitos'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas precisam ser iguais',
  ),
});
