import {
  forwardRef,
  ForwardRefRenderFunction,
  TextareaHTMLAttributes,
} from 'react';

import * as S from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string | null;
}

const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = ({ id, error, ...rest }, ref) => {
  return <S.Container {...rest} ref={ref} id={id} error={!!error} />;
};

export const TextArea = forwardRef(TextAreaBase);
