import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card } from '../../components/Card';
import { useAuth } from '../../hooks/auth';
import { getAllBids } from '../../services/auction';
import { formatMonetaryValue } from '../../utils/formatMonetaryValue';
// FIX-ME
// import { verifyJwtToken } from '../../utils/verifyJwtToken';

import * as S from './styles';

export type Bid = {
  id: number;
  auction: {
    id: number;
  };
  value: number;
  created_at: Date;
  bid_auction_status: 'Live' | 'Tied' | 'Winner';
};

export function MyBids() {
  const { token, signOut } = useAuth();
  const [bids, setBids] = useState<Bid[]>([]);

  useEffect(() => {
    async function getUserBids() {
      if (!token) {
        return;
      }

      // verifyJwtToken(token, signOut);

      if (!token) {
        toast.error('Sua sessão expirou. Faça o login novamente.');
        return;
      }

      const response = await getAllBids(token);
      setBids(response.data);
    }

    getUserBids();
  }, [token, signOut]);

  function formatStatusString(status: 'Winner' | 'Tied' | 'Live') {
    switch (status) {
      case 'Winner':
        return 'Vencido';
      case 'Live':
        return 'Em andamento';
      default:
        return 'Perdido';
    }
  }
  return (
    <S.Container>
      <S.Title>Meus lances</S.Title>

      <Card>
        <S.Table>
          <thead>
            <tr>
              <th>Leilão</th>
              <th>Valor do lance</th>
              <th>Data</th>
              <th>Hora</th>
              <th className="right-align">Status</th>
            </tr>
          </thead>
          <tbody>
            {bids.map(bid => (
              <tr key={bid.id}>
                <td>
                  <Link to={`/leilao/${bid.auction.id}`}>
                    #{bid.auction.id}
                  </Link>
                </td>
                <td>{formatMonetaryValue(bid.value)}</td>
                <td>{format(new Date(bid.created_at), 'dd/MM/yyyy')}</td>
                <td>{format(new Date(bid.created_at), "HH'h'mm")}</td>
                <td
                  className={
                    bid.bid_auction_status === 'Winner'
                      ? 'right-align winner'
                      : 'right-align'
                  }
                >
                  {formatStatusString(bid.bid_auction_status)}
                </td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      </Card>
    </S.Container>
  );
}
