import { Card } from '../../components/Card';
import * as S from './styles';

export function AboutUs() {
  return (
    <S.Container>
      <Card>
        <h1>Sobre nós</h1>
        <p>
          Somos um canal exclusivo e inovador onde vendedores e compradores
          fazem negócios a partir de uma regra muito simples: uma única oferta
          ao menor preço do leilão.
        </p>
        <p>
          Nascemos já com uma pegada muito digital, que acompanha a
          transformação do mercado de pagamentos, moedas e ativos, e queremos
          que nossos clientes se sintam seguros em nosso ambiente.
        </p>
        <p>
          Nossos clientes podem anunciar e fazer ofertas de qualquer lugar do
          mundo, em inúmeras classes de produtos e experiências, de maneira
          intuitiva, objetiva e transparente.
        </p>
      </Card>
    </S.Container>
  );
}
